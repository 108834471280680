import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormInput } from "../../components/FormInput";
import { Row, Col, Form } from "reactstrap";
import { CustomSingleSelect } from "../../components/CustomSingleSelect";
import { states, source } from "../EmailScheduling/select_values";
import MuiAlert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Logo from "../../assets/logo.png";
import CardMedia from "@mui/material/CardMedia";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { submitFormData } from "../../api/invoice-api";
import Snackbar from "@mui/material/Snackbar";
import "yup-phone";
import InputMask from "react-input-mask";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = yup.object({
  firstname: yup
    .string("Enter First Name :")
    .required("First Name is required"),
  lastname: yup.string("Enter Last Name").required("Last Name is required"),
  address: yup.string("Enter Address").required("Address is required"),
  city: yup.string("Enter City").required("City is required"),
  /* email: yup
        .string('Enter Email')
        .required('Email is required'), */
  zipcode: yup.string("Enter Zip Code").required("Zip Code is required"),
  phone: yup
    .string("Enter Phone")
    .required("Phone is required")
    .phone("Invalid Phone"),
  /*source: yup
        .string('Enter Source')
        .required('Source is required'),
     message: yup
        .string('Enter message')
        .required('Message is required'), */
});

export const AddDetailsForm = () => {
  const [optIn, setOptIn] = React.useState(false);
  let initialValues = {
    state: "NY",
    firstname: "",
    lastname: "",
    address: "",
    city: "",
    zipcode: "",
    email: "",
    phone: "",
    voucher: "",
    source: "",
    optin: false,
    message: "",
  };
  const [snackBarState, setSnackBarState] = useState({
    openSnackbar: false,
    vertical: "top",
    horizontal: "center",
    message: "Successful !",
    severity: "success",
  });
  const { vertical, horizontal, openSnackbar, message, severity } =
    snackBarState;
  const handleSnackbarClose = () => {
    setSnackBarState({ ...snackBarState, openSnackbar: false });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values["optin"] = optIn;
      console.log(values);

      submitFormData(values).then((response) => {
        if (response.status === 200) {
          setSnackBarState({
            ...snackBarState,
            openSnackbar: true,
            message:
              "Thank you for providing your information to Life Tree Services LLC.",
            severity: "success",
          });
          formik.resetForm(initialValues);
          setOptIn(false);
        } else {
          setSnackBarState({
            ...snackBarState,
            openSnackbar: true,
            message: "Error providing information !",
            severity: "error",
          });
        }
      });
    },
  });

  return (
    <Box
      component="main"
      sx={{ flexGrow: 2, pt: 2, height: "100%", background: "#4CAF50" }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Card
          sx={{
            width: "90%",
            background: "#F9F9F9",
            color: "#666666",
            fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
          }}
          variant="outlined"
        >
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            key={vertical + horizontal}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
          <CardContent sx={{ m: 5 }}>
            <a href="https://www.lifetreewny.com/">
              <Grid container justifyContent="center">
                <CardMedia
                  component="img"
                  image={Logo}
                  alt="Life Tree LLC"
                  sx={{ height: "10%", width: "30%" }}
                ></CardMedia>
              </Grid>
            </a>
            <Typography variant="h4" align="center" sx={{ flexGrow: 1, p: 1 }}>
              Contact Us
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              Send us your message and we will get back to you as soon as
              possible.
            </Typography>
            <Col sm="12" md={{ size: 12 }}>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col sm="6">
                    <FormInput
                      id="firstname"
                      name="firstname"
                      label="First Name"
                      onChange={formik.handleChange}
                      value={formik.values.firstname}
                      helperText={
                        formik.touched.firstname && formik.errors.firstname
                      }
                      error={
                        formik.touched.firstname &&
                        Boolean(formik.errors.firstname)
                      }
                    />
                  </Col>

                  <Col sm="6">
                    <FormInput
                      id="lastname"
                      name="lastname"
                      label="Last Name"
                      onChange={formik.handleChange}
                      value={formik.values.lastname}
                      helperText={
                        formik.touched.lastname && formik.errors.lastname
                      }
                      error={
                        formik.touched.lastname &&
                        Boolean(formik.errors.lastname)
                      }
                    />
                  </Col>
                </Row>

                <FormInput
                  id="address"
                  name="address"
                  label="Address"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  helperText={formik.touched.address && formik.errors.address}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                />
                <Row>
                  <Col sm="4">
                    <FormInput
                      id="city"
                      name="city"
                      label="City"
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      helperText={formik.touched.city && formik.errors.city}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                    />
                  </Col>
                  <Col sm="4">
                    <FormInput
                      id="zipcode"
                      name="zipcode"
                      label="Zip Code"
                      onChange={formik.handleChange}
                      value={formik.values.zipcode}
                      helperText={
                        formik.touched.zipcode && formik.errors.zipcode
                      }
                      error={
                        formik.touched.zipcode && Boolean(formik.errors.zipcode)
                      }
                    />
                  </Col>
                  <Col sm="4">
                    <CustomSingleSelect
                      id="state"
                      name="state"
                      label="States"
                      optionList={states}
                      onChange={(e) => formik.handleChange(e)}
                      value={formik.values.state}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <InputMask
                      mask="(999) 999-9999"
                      value={formik.values.phone}
                      onChange={(e) => formik.handleChange(e)}
                      id="phone"
                      name="phone"
                      label="Phone"
                      helperText={formik.touched.phone && formik.errors.phone}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                    >
                      {(inputProps) => (
                        <FormControl sx={{ mt: 3, ml: 1, width: "100%" }}>
                          <TextField
                            label={inputProps.label}
                            id={inputProps.id}
                            name={inputProps.name}
                            value={inputProps.value}
                            multiline={inputProps.multiline}
                            placeholder={inputProps.placeholder}
                            variant="standard"
                            onChange={inputProps.onHandleChange}
                            helpertext={inputProps.helperText}
                            error={inputProps.error}
                            rows={inputProps.rows}
                          />
                        </FormControl>
                      )}
                    </InputMask>
                  </Col>
                  <Col sm="6">
                    <FormInput
                      id="email"
                      name="email"
                      label="Email"
                      placeholder="someone@example.com"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      helperText={formik.touched.email && formik.errors.email}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <FormInput
                      id="voucher"
                      name="voucher"
                      label="Voucher (Optional)"
                      onChange={formik.handleChange}
                      value={formik.values.voucher}
                      helperText={
                        formik.touched.voucher && formik.errors.voucher
                      }
                      error={
                        formik.touched.voucher && Boolean(formik.errors.voucher)
                      }
                    />
                  </Col>
                  <Col sm="6">
                    <CustomSingleSelect
                      id="source"
                      name="source"
                      label="How did you hear about us?"
                      optionList={source}
                      onChange={(e) => formik.handleChange(e)}
                      value={formik.values.source}
                    />
                  </Col>
                </Row>
                <FormInput
                  id="message"
                  name="message"
                  label="Message"
                  placeholder="Your Message here"
                  rows={4}
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  multiline={true}
                  helpertext={formik.touched.message && formik.errors.message}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                />
                <FormControlLabel
                  sx={{ ml: "2%" }}
                  control={
                    <Checkbox
                      id="optin"
                      name="optin"
                      value={formik.values.optin}
                      onChange={() => {
                        setOptIn(!optIn);
                      }}
                    />
                  }
                  label="I agree to receive emails that provides me with the information and offers provided by Life Tree Services LLC"
                />
                <Row>
                  <Box textAlign="center">
                    {
                      <Button color="primary" variant="contained" type="submit">
                        Submit
                      </Button>
                    }
                  </Box>
                </Row>
              </Form>
            </Col>
            <br />
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
};
