import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { EmailBuilder } from './EmailBuilder';
import { SentEmails } from './SentEmails';
import { DownloadContacts } from './DownloadContacts';
import { CustomerMailScheduler } from './CustomerMailScheduler';
import { EmailLogs } from './EmailLogs'; 
import PropTypes from 'prop-types';
import EmailTemplate from "./EmailTemplate";



export const EmailTabs = ({ token }) => {

    const [value, setValue] = React.useState(0);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box >
                        {children}
                    </Box>
                )}
            </div>
        );
    }


    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Potential Customers" {...a11yProps(0)} />
                    <Tab label="Existing Customers" {...a11yProps(1)} />
                    <Tab label="Sent Emails" {...a11yProps(2)} />
                    <Tab label="Download Contacts" {...a11yProps(3)} />
                    {/* <Tab label="View Logs" {...a11yProps(4)} /> */}
                    <Tab label="Email Template" {...a11yProps(4)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <CustomerMailScheduler token={token} customerType={value} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CustomerMailScheduler token={token} customerType={value} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <SentEmails token={token} customerType={value} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <DownloadContacts token={token} customerType={value} />
            </TabPanel>
            {/* <TabPanel value={value} index={4}>
                <EmailLogs token={token} customerType={value} />
            </TabPanel> */}
            <TabPanel value={value} index={4}>
                <EmailTemplate token={token} customerType={value} />
            </TabPanel>
        </Box>
    );
};
