import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useFormik } from 'formik';
import { CustomSingleSelect } from '../../components/CustomSingleSelect';
import { CustomMultiSelect } from '../../components/CustomMultiSelect';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { Button, AntSwitch, Stack } from '@mui/material';
import { fetchEmail, getCustomersForEmail, getJobType, sendSingleEmail, stopEmailCron, startFollowupEmails, stopFollowupEmails } from '../../api/invoice-api';
import { startEmails, stopEmails, getCronJobStatus, updateCronJobStatus } from '../../api/invoice-api';
import { dayOrMonthList, insertAttributesList, jobTypeList, proposal_status, operationList } from "./select_values";
import { FormInput } from '../../components/FormInput';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from 'moment'
import { Row, Col, Form } from "reactstrap";
import { textAlign } from '@mui/system';

import { SentEmailsModal } from './SentEmailsModal';
import { green } from '@mui/material/colors';



export const CustomerMailScheduler = ({ token, customerType }) => {


    //get the data from db and use it to set the initial state
    const [potCustEmailFollowupEnabled, setPotCustEmailFollowupEnabled] = useState(false);
    const [existCustEmailFollowupEnabled, setExistCustEmailFollowupEnabled] = useState(false);
    const [cronJobStatus, setCronJobStatus] = useState(false);

    const gridStyle = { marginTop: 10, height: '69vh', overflow: 'auto' }
        const [rows, setRows] = useState([]);
        const [value, setValue] = React.useState('');
    
       // const [selectedAttribute, setSelectedAttribute] = React.useState(insertAttributesList[0].value);
        const [dayOrMonth, setDayOrMonth] = React.useState(dayOrMonthList[0].value);
        const [operation, setoperation] = React.useState(operationList[0].value);
        const [jobType, setJobType] = React.useState([]);
        


        const [initialValues, setInitialValues] = React.useState({
            id: '',
            proposal_status: proposal_status[customerType].value,
            last_days_months: '',
            day_or_month: '',
            job_type: [],
            type: customerType,
            operation: ''
        });
    
        const handleDayOrMonthChange = (e) => setDayOrMonth(e.target.value);
        const handleOperationChange = (e) => setoperation(e.target.value);
        const handleJobTypeChange = (e) => {
            setJobType(e.target.value);
        }
    
        useEffect(() => {
            console.log(jobType);
        }, [jobType])
    
        const formik = useFormik({
            enableReinitialize: true,
            initialValues: initialValues,
    
            onSubmit: (values) => {
                let jobTypeStr = "";
                jobType.map((job) => {
                    jobTypeStr += job + ",";
                });
    
                jobTypeStr = jobTypeStr.slice(0, -1);
                console.log(jobTypeStr);
    
                let formObject = {
                    proposal_status: proposal_status[customerType].value,
                    last_days_months: values.last_days_months,
                    id: values.id,
                    day_or_month: dayOrMonth,
                    job_type: jobTypeStr,
                    type: customerType,
                    operation: values.operation
                }
            }
        });

    var columns = [
        {
            name: "First Name",
            header: "First Name",
            visible: true,
            //defaultWidth: 400,
            enableColumnFilterContextMenu: true
        },
        {
            name: "Last Name",
            header: "Last Name",
            visible: true,
            //defaultWidth: 400,
            enableColumnFilterContextMenu: true
        }, 
        {
            name: "Email",
            header: "Email ID",
            defaultWidth: 280,
            visible: true,
            enableColumnFilterContextMenu: true
        }, 
        {
            name: "Proposal Status",
            header: "Proposal Status",
            visible: true,
            //defaultWidth: 400,
            enableColumnFilterContextMenu: true
        }, 
        {
            name: "Total",
            header: "Total",
            visible: true,
            defaultWidth: 100,
            enableColumnFilterContextMenu: true
        }, 
        {
            name: "Date",
            header: "contacted On",
            visible: true,
            defaultWidth: 125,
            enableColumnFilterContextMenu: true,
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                return {
                    dateFormat: 'MM-DD-YYYY',
                    cancelButton: false,
                    highlightWeekends: false,
                }
            },
            render: ({ value, cellProps }) => value ? moment(value).format('MM-DD-YYYY') : ''
            
        },
        {
            name: "email_sent_date",
            header: "Last Email Sent On",
            visible: true,
            defaultWidth: 125,
            enableColumnFilterContextMenu: true,
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                return {
                    dateFormat: 'MM-DD-YYYY',
                    cancelButton: false,
                    highlightWeekends: false,
                }
            },
            render: ({ value, cellProps }) => value ? moment(value).format('MM-DD-YYYY') : ''
        },
        {
            name: "Send",
            header: "Send Email",
            visible: true,
            defaultWidth: 165,
            enableColumnFilterContextMenu: true,
            render: ({ cellProps, data }) => {
                return (
                    <Button color="primary" variant="contained" onClick={(event) => sendEmailToParticularPerson(cellProps.rowIndex, data)}>
                        Send Email
                    </Button>)
            }
        },
        {
            name: "email_body",
            header: "Email Body",
            visible: true,
            defaultWidth: 200,
            enableColumnFilterContextMenu: true,
            render: ({ cellProps, data }) => {
              return  (
                    <Button color="primary" variant="contained" onClick={() => viewEmail(cellProps.rowIndex, data)}>
                        View Email Body
                    </Button>)
            }
        }
       
    ];
    columns = columns.filter(col => col.visible === true);

    const [emailObj, setEmailObj] = React.useState('');

    const prepareResponse = (response, emailId) => {
        console.log('this response is from front end', response, response.data['template_subject']);
        const value = {
            email_body: response.data['template_body'],
            subject: response.data['template_subject'],
            email_id: emailId
        }
        setEmailObj(value);
    };

    const viewEmail = (index, data) => {
        console.log(index, data);
        const firstName = data["First Name"];
        const lastName = data["Last Name"];
        const emailId = data["Email"];
        const proposalID = data["Proposal ID"];
        console.log(firstName, lastName, emailId, proposalID);
        const numberOfDays = (Math.floor((new Date() - new Date(data["Date"]))/86400000));
        console.log((new Date() - new Date(data["Date"]))/86400000);
        console.log("number of days", numberOfDays);
        if(customerType === 0)
        {
            if(numberOfDays === 3) {
                fetchEmail(token, '3 Days', firstName, lastName).then((response) => {
                    prepareResponse(response, emailId);
                });
            }
            else if(numberOfDays > 3 && numberOfDays < 7) {
                fetchEmail(token, 'More than 3, Less than 7 Days', firstName, lastName).then((response) => {
                    prepareResponse(response, emailId);
                });
            }
            else if(numberOfDays < 3) {
                fetchEmail(token, 'Less than 3 Days', firstName, lastName).then((response) => {
                    prepareResponse(response, emailId);
                });
            }
            else if(numberOfDays === 7) {
                fetchEmail(token, '7 Days', firstName, lastName).then((response) => {
                    prepareResponse(response, emailId);
                });
            }
            else if(numberOfDays > 7 && numberOfDays < 10) {
                fetchEmail(token, 'More than 7, Less than 10 Days', firstName, lastName).then((response) => {
                    prepareResponse(response, emailId);
                });
            }
            else if(numberOfDays === 10) {
                fetchEmail(token, '10 Days', firstName, lastName).then((response) => {
                    prepareResponse(response, emailId);
                });
            }
            else if(numberOfDays > 10 && numberOfDays < 30) {
                fetchEmail(token, 'More than 10, Less than 30 Days', firstName, lastName).then((response) => {
                    prepareResponse(response, emailId);
                });
            }
            else if(numberOfDays === 30) {
                fetchEmail(token, '30 Days', firstName, lastName).then((response) => {
                    prepareResponse(response, emailId);
                });
            }
            else if(numberOfDays > 30 && numberOfDays < 90) {
                fetchEmail(token, 'More than 30, Less than 90 Days', firstName, lastName).then((response) => {
                    prepareResponse(response, emailId);
                });
            }
            else if(numberOfDays === 90) {
                fetchEmail(token, '90 Days', firstName, lastName).then((response) => {
                    prepareResponse(response, emailId);
                });
            }
            else if(numberOfDays > 90) {
                fetchEmail(token, 'More than 90 Days', firstName, lastName).then((response) => {
                    prepareResponse(response, emailId);
                });
            }
        }
        else {
            console.log(data);
            var job_type = '';
            getJobType(token, proposalID).then((response) => {
                var jobTypeIndex = 0;
                for(var i = 0; i < response.data.length; i++) {
                    if(jobType.includes(response.data[i]['Item'].trim())) {
                        console.log("inside if loop");
                        jobTypeIndex = i;
                        job_type = response.data[i]['Item'].trim();
                        break;
                    }
                }
                console.log("job_type: ", job_type);
            }).then((response) => {
                if(job_type === "Tree Trimming Class I Fine Pruning") {
                    fetchEmail(token, 'tree trimming class 1 fine pruning', firstName, lastName).then((response) => {
                        prepareResponse(response, emailId);
                    });
                }
                else {
                    fetchEmail(token, job_type, firstName, lastName).then((response) => {
                        prepareResponse(response, emailId);
                    }).catch((error) => {
                        alert('Template for job type: ' + job_type + ' not found. Please add using Email template');
                    });
                }
            })
        }
    };

    const sendEmailToParticularPerson = (index, data) => {
        console.log(index, data);
        const firstName = data["First Name"];
        const lastName = data["Last Name"];
        const emailId = data["Email"];
        const proposalID = data["Proposal ID"];
        const dropbox_url = data['dropbox_url'];

        //converting timestamp to UTC as MYSQL databse stores UTC timestamp
        const numberOfDays = (Math.floor(((moment.utc() - moment.utc(data["Date"]))/86400000)))
        console.log((new Date(Date.UTC()) - new Date(data["Date"]))/86400000);
        console.log("number of days", numberOfDays);
        if(customerType === 0)
        {
            if(numberOfDays === 3) {
                fetchEmail(token, '3 Days', firstName, lastName).then((response) => {
                   
                    const value = {
                        email_body: response.data['template_body'],
                        subject: response.data['template_subject'],
                        email_id: emailId,
                        proposalID : proposalID,
                        dropbox_url : dropbox_url
                    }
                    sendSingleEmail(token, value);
                });
            }
            else if(numberOfDays > 3 && numberOfDays < 7) {
                fetchEmail(token, 'More than 3, Less than 7 Days', firstName, lastName).then((response) => {
                   const value = {
                    email_body: response.data['template_body'],
                    subject: response.data['template_subject'],
                    email_id: emailId,
                    proposalID : proposalID,
                    dropbox_url : dropbox_url
                }
                sendSingleEmail(token, value);
                });
            }
            else if(numberOfDays < 3) {
                fetchEmail(token, 'Less than 3 Days', firstName, lastName).then((response) => {
                    const value = {
                        email_body: response.data['template_body'],
                        subject: response.data['template_subject'],
                        email_id: emailId,
                        proposalID : proposalID,
                        dropbox_url : dropbox_url
                    }
                    sendSingleEmail(token, value);
                });
            }
            else if(numberOfDays === 7) {
                fetchEmail(token, '7 Days', firstName, lastName).then((response) => {
                   const value = {
                    email_body: response.data['template_body'],
                    subject: response.data['template_subject'],
                    email_id: emailId,
                    proposalID : proposalID,
                    dropbox_url : dropbox_url
                }
                sendSingleEmail(token, value);
                });
            }
            else if(numberOfDays > 7 && numberOfDays < 10) {
                fetchEmail(token, 'More than 7, Less than 10 Days', firstName, lastName).then((response) => {
                    const value = {
                        email_body: response.data['template_body'],
                        subject: response.data['template_subject'],
                        email_id: emailId,
                        proposalID : proposalID,
                        dropbox_url : dropbox_url
                    }
                    sendSingleEmail(token, value);
                });
            }
            else if(numberOfDays < 7) {
                fetchEmail(token, 'Less than 7 Days', firstName, lastName).then((response) => {
                    const value = {
                        email_body: response.data['template_body'],
                        subject: response.data['template_subject'],
                        email_id: emailId,
                        proposalID : proposalID,
                        dropbox_url : dropbox_url
                    }
                    sendSingleEmail(token, value);
                });
            }
            else if(numberOfDays === 10) {
                fetchEmail(token, '10 Days', firstName, lastName).then((response) => {
                    const value = {
                        email_body: response.data['template_body'],
                        subject: response.data['template_subject'],
                        email_id: emailId,
                        proposalID : proposalID,
                        dropbox_url : dropbox_url
                    }
                    sendSingleEmail(token, value);
                });
            }
            else if(numberOfDays > 10 && numberOfDays < 30) {
                fetchEmail(token, 'More than 10, Less than 30 Days', firstName, lastName).then((response) => {
                   const value = {
                    email_body: response.data['template_body'],
                    subject: response.data['template_subject'],
                    email_id: emailId,
                    proposalID : proposalID,
                    dropbox_url : dropbox_url
                }
                sendSingleEmail(token, value);
                });
            }
            else if(numberOfDays === 30) {
                fetchEmail(token, '30 Days', firstName, lastName).then((response) => {
                    const value = {
                        email_body: response.data['template_body'],
                        subject: response.data['template_subject'],
                        email_id: emailId,
                        proposalID : proposalID,
                        dropbox_url : dropbox_url
                    }
                    sendSingleEmail(token, value);
                });
            }
            else if(numberOfDays > 30 && numberOfDays < 90) {
                fetchEmail(token, 'More than 30, Less than 90 Days', firstName, lastName).then((response) => {
                   const value = {
                    email_body: response.data['template_body'],
                    subject: response.data['template_subject'],
                    email_id: emailId,
                    proposalID : proposalID,
                    dropbox_url : dropbox_url
                }
                sendSingleEmail(token, value);
                });
            }
            else if(numberOfDays === 90) {
                fetchEmail(token, '90 Days', firstName, lastName).then((response) => {
                    const value = {
                        email_body: response.data['template_body'],
                        subject: response.data['template_subject'],
                        email_id: emailId,
                        proposalID : proposalID,
                        dropbox_url : dropbox_url
                    }
                    sendSingleEmail(token, value);
                });
            }
            else if(numberOfDays > 90) {
                fetchEmail(token, 'More than 90 Days', firstName, lastName).then((response) => {
                   const value = {
                    email_body: response.data['template_body'],
                    subject: response.data['template_subject'],
                    email_id: emailId,
                    proposalID : proposalID,
                    dropbox_url : dropbox_url
                }
                sendSingleEmail(token, value);
                });
            }
        }
        else {
            console.log(data);
            var job_type = '';
            getJobType(token, proposalID).then((response) => {
                var jobTypeIndex = 0;
                for(var i = 0; i < response.data.length; i++) {
                    if(jobType.includes(response.data[i]['Item'].trim())) {
                        console.log("inside if loop");
                        jobTypeIndex = i;
                        job_type = response.data[i]['Item'].trim();
                        break;
                    }
                }
                console.log("job_type: ", job_type);
            }).then((response) => {
                if(job_type === "Tree Trimming Class I Fine Pruning") {
                    fetchEmail(token, 'tree trimming class 1 fine pruning', firstName, lastName).then((response) => {
                        const value = {
                            email_body: response.data['template_body'],
                            subject: response.data['template_subject'],
                            email_id: emailId,
                            proposalID : proposalID,
                            dropbox_url : dropbox_url
                        }
                        sendSingleEmail(token, value);
                    });
                }
                else {
                    fetchEmail(token, job_type, firstName, lastName).then((response) => {
                        const value = {
                            email_body: response.data['template_body'],
                            subject: response.data['template_subject'],
                            email_id: emailId,
                            proposalID : proposalID,
                            dropbox_url : dropbox_url
                        }
                        sendSingleEmail(token, value);
                    }).catch((error) => {
                        alert('Template for job type: ' + job_type + ' not found. Please add using Email template');
                    });
                }
            })
        }
    };




    const getallEmails = () => {
        let jobTypeStr = "";
        jobType.map((job) => {
            jobTypeStr += job + ",";
        });

        jobTypeStr = jobTypeStr.slice(0, -1);
        console.log(jobTypeStr);
        let formObject = {
            proposal_status: proposal_status[customerType].value,
            last_days_months: formik.values.last_days_months,
            id: formik.values.id === '' ? -1 : formik.values.id,
            day_or_month: dayOrMonth,
            job_type: jobTypeStr,
            type: customerType,
            operation: operation
            
        }
        console.log('customertype variable:',customerType);
        setValue(formObject);
        getCustomersForEmail(token, formObject).then(response => {
            setRows([]);
            const data = response.data;
            console.log('hihi', data);
            setRows(data);
        });
    };

    const startFollowupCron = () => {
        let formObject = {
            proposal_status: proposal_status[customerType].value,
            customer_type: customerType
        };

        let cronObject = {
            customer_type: customerType,
            //cron_running: potCustEmailFollowupEnabled
            cron_running: true
        };

        startFollowupEmails(token, formObject).then((repsonse) => {
            console.log("cron started");
            updateCronJobStatus(token, cronObject).then((repsonse) => {
                console.log("response");
                getCronJobStatus(token, customerType).then((response) => {
                    console.log(response);
                    setCronJobStatus(response.data['cron_running']);
                    console.log(response.data['cron_running']);
                })
            })
        });
    }

    const stopFollowupCron = async () => {
        let formObject = {
            proposal_status: proposal_status[customerType].value,
            customer_type: customerType
        };
        
        let cronObject = {
            customer_type: customerType,
            //cron_running: potCustEmailFollowupEnabled
            cron_running: false
        };

        stopFollowupEmails(token, formObject).then((repsonse) => {
            console.log("cron stopped");
            updateCronJobStatus(token, cronObject).then((repsonse) => {
                console.log("response");
                getCronJobStatus(token, customerType).then((response) => {
                    console.log(response);
                    setCronJobStatus(response.data['cron_running']);
                    console.log(response.data['cron_running']);
                })
            })
        });
    }

    const startCron = () => {
        let formObject = {
            proposal_status: proposal_status[customerType].value,
            customer_type: customerType
        };

        let cronObject = {
            customer_type: customerType,
            //cron_running: existCustEmailFollowupEnabled
            cron_running: true
        };

        startEmails(token, formObject).then((repsonse) => {
            console.log("cron started");
            updateCronJobStatus(token, cronObject).then((repsonse) => {
                console.log("response");
                getCronJobStatus(token, customerType).then((response) => {
                    console.log(response);
                    setCronJobStatus(response.data['cron_running']);
                    console.log(response.data['cron_running']);
                })
            })
        });
    }

    const stopCron = () => {
        let formObject = {
            proposal_status: proposal_status[customerType].value,
            customer_type: customerType
        };

        let cronObject = {
            customer_type: customerType,
            //cron_running: existCustEmailFollowupEnabled
            cron_running: false
        };

        stopEmails(token, formObject).then((repsonse) => {
            console.log("cron stopped");
            updateCronJobStatus(token, cronObject).then((repsonse) => {
                console.log("response");
                getCronJobStatus(token, customerType).then((response) => {
                    console.log(response);
                    setCronJobStatus(response.data['cron_running']);
                    console.log(response.data['cron_running']);
                })
            })
        });
    }


    useEffect(() => {
        getCronJobStatus(token, customerType).then((response) => {
            console.log(response);
            setCronJobStatus(response.data['cron_running']);
            console.log(response.data['cron_running']);
        });
    }, []);

    useEffect(() => {
        customerType ? setExistCustEmailFollowupEnabled(cronJobStatus) : setPotCustEmailFollowupEnabled(cronJobStatus);
    }, [cronJobStatus]);

    return (
        <Box sx={{ height: "100%" }}>
            <br />
            <Typography variant="h4" sx={{ flexGrow: 1, p: 1 }}>
                Email Scheduling
            </Typography>
                <Form onSubmit={formik.handleSubmit}> 
                
                    <Row>
                        <Col sm="1" style={{ width: '20%' }}>
                            <FormInput
                                id="last_days_months"
                                name="last_days_months"
                                label="Last Contacted (Days/Months Ago)"
                                placeholder="Enter Number of days/Months"
                                variant="standard"
                                error={formik.touched.last_days_months && Boolean(formik.errors.last_days_months)}
                                value={formik.values.last_days_months}
                                onChange={formik.handleChange}
                                helpertext={formik.touched.last_days_months && formik.errors.last_days_months}
                            />
                        </Col>
                        <Col sm="2" style={{ width: '25%' }}>
                            <CustomSingleSelect
                                id="operation"
                                name="operation"
                                label="Operation"
                                optionList={operationList}
                                onChange={handleOperationChange}
                                value={operation}
                            />
                        </Col>
                        <Col sm="3" style={{ width: '25%' }}>
                            <CustomSingleSelect
                                id="day_or_month"
                                name="day_or_month"
                                label="Day Or Months"
                                optionList={dayOrMonthList}
                                onChange={handleDayOrMonthChange}
                                value={dayOrMonth}
                            />
                        </Col>
                        {customerType === 1 &&
                        (
                                <Col sm="4" style={{ width: '30%' }}>
                                    <CustomMultiSelect
                                        id="job_type"
                                        name="job_type"
                                        label="Job Type"
                                        optionList={jobTypeList}
                                        onChange={handleJobTypeChange}
                                        value={jobType}
                                    />
                                </Col>       
                        )
                    }
                    </Row>
                    {customerType ? <>
                        <Row>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                            {/* {cronJobStatus ?
                            <Box component="div" sx={{ display: 'flex', boxShadow: 1, color: 'green', marginRight: '20px', alignItems: 'center', borderRadius: '5px' }}>Jobs status: Running</Box> 
                            :<Box component="div" sx={{ display: 'flex', boxShadow: 1, color: 'red', marginRight: '20px', alignItems: 'center', borderRadius: '5px' }}>Jobs status: Not Running</Box>} */}

                            <Button color="primary" variant="contained" style={{ justifyContent: 'center', marginRight: '20px'}} onClick={() => getallEmails()}>Search</Button> 

                            { existCustEmailFollowupEnabled ?            
                                    <Button color="error" variant="contained" style={{ justifyContent: 'center', marginRight: '20px'}} onClick={() => {stopCron(); setExistCustEmailFollowupEnabled(false)}}>Disable Automated Emails</Button>
                                                    :
                                    <Button color="success" variant="contained" style={{ justifyContent: 'center', marginRight: '20px'}}onClick={() => {startCron(); setExistCustEmailFollowupEnabled(true) }}>Enable Automated Emails</Button>

                            }

                        </div>                 
                        </Row>
                       
                    </> 
                    : <>
                        <Row>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                            {/* {cronJobStatus ?
                            <Box component="div" sx={{ display: 'flex', boxShadow: 1, color: 'green', marginRight: '20px', alignItems: 'center', borderRadius: '5px' }}>Jobs status: Running</Box> 
                            :<Box component="div" sx={{ display: 'flex', boxShadow: 1, color: 'red', marginRight: '20px', alignItems: 'center', borderRadius: '5px' }}>Jobs status: Not Running</Box>} */}
                            
                            <Button color="primary" variant="contained" style={{ justifyContent: 'center', marginRight: '20px'}} onClick={() => getallEmails()}>Search</Button>  

                            {potCustEmailFollowupEnabled ?          
                                               <Button color="error" variant="contained" style={{ justifyContent: 'center', marginRight: '20px'}} onClick={() => {stopFollowupCron();  setPotCustEmailFollowupEnabled(false)}}>Disable Automated Emails</Button>
                                                        :
                                                <Button color="success" variant="contained" style={{ justifyContent: 'center', marginRight: '20px'}} onClick={() => {startFollowupCron(); setPotCustEmailFollowupEnabled(true)}}>Enable Automated Emails</Button>
                            }
                            
                           </div>
                        </Row>       
                    </>}
                </Form>
            <Divider />
            <>
                {rows ? <ReactDataGrid
                    idProperty="Proposal ID"
                    style={gridStyle}
                    columns={columns}
                    dataSource={rows}
                    pagination
                    theme='green-light'
                    defaultLimit={10}
                    defaultSortInfo={{ name: 'id', dir: -1 }}
                    pageSizes={[10, 25, 50]}
                    enableColumnFilterContextMenu={true}
                /> : ""}
                {<SentEmailsModal token={token} value={emailObj} setValue={setEmailObj} />}
            </>
        </Box>
    );
};
