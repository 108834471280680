export const jobColumns = [{
    name: "ID",
    header: "ID",
    visible: false,
    defaultWidth: 80,
}, {
    name: "FK_Proposal_ID",
    header: "FK_Proposal_ID",
    visible: false
}, {
    name: "Item",
    header: "Item",
    visible: true,
    defaultWidth: 300,
}, {
    name: "Item Description",
    header: "Item Description",
    visible: true,
    defaultWidth: 500,
}, {
    name: "Quantity",
    header: "Quantity",
    visible: true
}, {
    name: "UOM",
    header: "UOM",
    visible: false
}, {
    name: "Taxable",
    header: "Taxable",
    visible: true
}, {
    name: "Timestamp",
    header: "Timestamp",
    visible: false
}, {
    name: "Unit Price",
    header: "Unit Price",
    visible: true
}];