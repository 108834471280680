import axios from 'axios';
import { proposal_status } from '../pages/EmailScheduling/select_values';
// http://137.184.136.1/api/dashboard/proposals/all
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
    //baseURL: 'http://localhost:4000/'
})


export const getAllInvoiceDetails = token => api.get(`/dashboard/proposals/all`, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})

export const getEmails = token => api.get(`/dashboard/email/details`, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})

export const updateProposal = (token, selection) => api.post(`/dashboard/proposals/update/`, selection, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    },

})

export const getAllFormData = token => api.get('/dashboard/formData/', {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})



export const enqueueQuickbookJobs = (token, payload) => api.post(`/dashboard/quickbooks/queue/enqueue/`, payload, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    },

})

export const updateEmailCron = (token, payload) => api.post(`/dashboard/email/update`, payload, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    },

})

export const insertEmailCron = (token, payload) => api.post(`/dashboard/email/insert`, payload, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    },

})

export const sendSingleEmail = (token, payload) => api.post(`/dashboard/email/send-single-email`, payload, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})

export const stopEmailCron = (token, payload) => api.post(`/dashboard/email/stop-cron`, payload, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})

export const addEmailTemplate = (token, payload) => api.post(`/dashboard/email/add-template`, payload, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})

export const updateEmailTemplate = (token, payload) => api.post(`/dashboard/email/update-template`, payload, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})

export const fetchEmailTemplate = (token, payload) => api.get(`/dashboard/email/fetch-template/` + payload, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})

export const fetchEmail = (token, jobType, firstName, lastName) => api.get('/dashboard/email/fetch-email/' + jobType + '?firstName=' + firstName + '&lastName=' + lastName, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})

export const getJobType = (token, proposalID) => api.get('dashboard/email/getJobType/' + proposalID, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})

export const getQuickbookQueueData = (token) => api.get('/dashboard/quickbooks/queue/', {
    headers: {
        // 'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})
export const deleteQb = (token, payload) => api.post(`/dashboard/quickbooks/queue/delete/`, payload, {
    headers: {
        // 'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})

export const getCoordinates = (token) => api.get('/map-view/get-coordinates', {
    headers: {
        // 'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})


export const getCronStatus = (token, customerType) => api.get(`/dashboard/email/get-cron-status?customerType=` + customerType, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})

export const getAllCronStatuses = (token) => api.get(`/dashboard/email/get-all-cron-statuses` , {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})

export const submitFormData = payload => api.post(`/form/post/`, payload);


export const getCustomersForEmail = (token, payload) => api.post(`/dashboard/email/get-customers-for-email/`, payload, {
//export const getCustomersForEmail = (token, payload) => api.get(`/dashboard/email/get-customers-for-email/`, payload, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})


export const getSentEmails = (token) => api.get(`/dashboard/email/get-sent-emails`, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})


export const getEmailAddresses = (token, proposal_status) => api.get(`/dashboard/email/get-email-addresses/` + proposal_status, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})



export const getQbPath = (token, proposal_status) => api.get(`/dashboard/quickbooks/queue/get-qb-path/`, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
})


export const updateQbPath = (token, selection) => api.post(`/dashboard/quickbooks/queue/update-qb-path/`, selection, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    },

});

export const startFollowupEmails = (token, cronDetails) => api.post('/dashboard/email/start-followup-emails', cronDetails, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
});

export const stopFollowupEmails = (token, cronDetails) => api.post('/dashboard/email/stop-followup-emails', cronDetails, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
});

export const startEmails = (token, cronDetails) => api.post('/dashboard/email/start-emails', cronDetails, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
});

export const stopEmails = (token, cronDetails) => api.post('/dashboard/email/stop-emails', cronDetails, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
});

export const getCronJobStatus = (token, customerType) => api.get('/dashboard/email/get-cron-job-status/' + customerType, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
});

export const updateCronJobStatus = (token, payload) => api.post('/dashboard/email/update-cron-job-status', payload, {
    headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
    }
});

const apis = {
    api,
    getAllInvoiceDetails,
    enqueueQuickbookJobs,
    getQuickbookQueueData,
    getAllFormData,
    getCronStatus,
    getAllCronStatuses,
    getEmails,
    submitFormData,
    insertEmailCron,
    getSentEmails,
    getEmailAddresses,
    getJobType,
    updateQbPath,
    getQbPath
}

export default apis;