import React, { useEffect } from 'react';
import { GoogleMap, withScriptjs, Marker, InfoWindow } from "react-google-maps";
import { getCoordinates } from "../api/invoice-api";
import withGoogleMap from "react-google-maps/lib/withGoogleMap";
import Divider from '@mui/material/Divider';
import "./Map.scss";
import { Box } from '@material-ui/core';
import mapStyles from '../style/mapStyles';
import ReactDataGrid from "@inovua/reactdatagrid-community";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";

let res = []

const options = {
  styles: mapStyles,
  disableDefaultUI : true,
  zoomControl : true,
}


const WrappedMap = withScriptjs(withGoogleMap(({token}) => {

  const [ApiRows, setApiRows] = React.useState([]);
  const [mapmarkersList, setMapmarkersList] = React.useState([]);
  const [selectedZipcode, setSelectedZipcode] = React.useState("");
  const [selectedMarker, setSelectedMarker] = React.useState(null);
  const [tableRows, setTableRows] = React.useState([]);



  // console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
  var defaultlat = 42.9950752
  var defaultlng = -78.744587
  if (mapmarkersList.length > 0) {
    // console.log("here")
    defaultlat = mapmarkersList[0].latitude
    defaultlng = mapmarkersList[0].longitude
  }

  useEffect(() => {
    // API Calls

    getLocationCoordinates(token);

  }, []);
  useEffect(() => {
    (setMarkers());
  }, [ApiRows, selectedZipcode])


//console.log(newDate)
  function setMarkers() {
    if (ApiRows.length > 0) {
      let hideJobslist = []
      let currentDate = new Date()
      for (var index = 0; index < ApiRows.length; index++) {
        let jobdate = new Date(ApiRows[index]['job_date'].slice(0, 10))
        let diffdays = Math.abs(currentDate - jobdate) / (1000 * 60 * 60 * 24)
        if (ApiRows[index]['proposal_status'] === 'Won' && ApiRows[index]['job_status'] !== "Completed") {//qbexported and status ApiRows[index]['qb'] !== 1 &&
          // console.log(ApiRows[index]['job_status'])
          if (diffdays <= 60) {
            hideJobslist.push(ApiRows[index])
          }

        }
      }

      setMapmarkersList(hideJobslist)


      const tableRows = hideJobslist.map( (item, index) => ({
                    sno: index+1,
                    proposal_id: item.proposal_id,
                        name : item.firstName + " " + item.lastName,
                                                  address: item.address,
                                                  amount: item.total,
                                                  dropbox_link: item.dropbox_link ? item.dropbox_link : "unavailable"}));

      setTableRows(tableRows);


    }
  }

  const getLocationCoordinates = (token) => {


    getCoordinates(token).then(resp => {
      res = resp.data
      setApiRows(res)
    });

  }

  const handleRowClick = (rowProps, event) =>{


    let selected_prop = rowProps.data.proposal_id;

    let selected_marker = mapmarkersList.find( (x) => x.proposal_id === selected_prop);

    setSelectedMarker(selected_marker);
  }


  const gridStyle = { minHeight: 200, marginTop: 10 , width:`100%`}

  var columns = [
    {
      name: "sno",
      header: "S. No.",
      visible: true,
      enableColumnFilterContextMenu: true,
      type: 'number',
      defaultWidth: 100,
    },
    {
      name: "name",
      header: "Name",
      visible: true,
      enableColumnFilterContextMenu: false
    },
    {
      name: "address",
      header: "Address",
      visible: true,
      enableColumnFilterContextMenu: true,
      render: ({ value }) => {
        return <Tooltip title={value} enterTouchDelay={0}><Box>{value}</Box></Tooltip>
      },
    },
    {
      name: "amount",
      header: "Amount($)",
      visible: true,
      enableColumnFilterContextMenu: true,
      type: 'number'
    },
    {
      name: "dropbox_link",
      header: "Dropbox Link",
      visible: true,
      enableColumnFilterContextMenu: true,
      render: ({ value }) => {

        {
          return value !== "unavailable" ?  <Link href={value} enterTouchDelay={0}><Box>Click Here</Box></Link> : ""
        }
      },
    },{

      name: "propsal_id",
      header: "Proposal Id",
      visible: false,
      enableColumnFilterContextMenu: true

    }
  ];

  columns = columns.filter(col => col.visible === true);



  return (
      <Box>
      <GoogleMap
          defaultZoom={12.5}
          defaultCenter={{ lat: defaultlat, lng: defaultlng }}
          options={options}

      >{
        mapmarkersList.map((row, index) => (

            (row.latitude != null && row.longitude != null) && (<Marker
                key={row.key}
                position={{
                  lat: row.latitude,
                  lng: row.longitude,
                }}
                onClick={() => {
                  setSelectedMarker(row)
                }}
                title={row.firstName + " " + row.lastName + ":" + row.address}

                icon = {{
                  anchor : new window.google.maps.Point(16, 33),
                  url: `/${row.total > 3000 ? "hv" : row.total > 1000 ? "mv" : "lv"}_markers/number_${index+1}.png`
                }}

            />)))
      }
        {selectedMarker && (
            <InfoWindow pixelOffset={"0"}
                        onCloseClick={() => {
                          setSelectedMarker(null)
                        }}
                        position={{

                          lat: selectedMarker.latitude,
                          lng: selectedMarker.longitude,
                        }}
            >
              <div>{"Name: " + selectedMarker.firstName + " " + selectedMarker.lastName}
                <br></br>{"Address: " + selectedMarker.address}
                <br></br>{"Amount: $" + selectedMarker.total}

                <br></br>{selectedMarker.dropbox_link != null ? <a href={selectedMarker.dropbox_link}>Dropbox Link</a> : "Dropbox Link : unavailable" }

              </div>

            </InfoWindow>
        )}
      </GoogleMap>
        <Divider />
        <ReactDataGrid
            idProperty="Proposal ID"
            style={gridStyle}
            columns={columns}
            theme='green-light'

            dataSource={tableRows}
            pagination
            defaultLimit={10}
            pageSizes={[10, 25, 50]}
            enableColumnFilterContextMenu={true}
            onRowClick={handleRowClick}
        />

      </Box>
  )
}));





export const Map = ({ token }) => {



  return (
    <Box id="root" sx={{ overflow: 'hidden', height: '88vh'}}>

      <Divider />

      <WrappedMap token={token}
        googleMapURL={'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=' + process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: `60%` }} />} />

    </Box>
  );

}
