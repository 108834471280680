import { useEffect, useState } from "react";
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Typography from '@mui/material/Typography';
import apis from "../../../api/invoice-api";
import Box from '@mui/material/Box';
import {invoiceColumns} from "./datagrid_columns.js";
import { Divider } from "@mui/material";

export const InvoiceModal = ({token, proposalId, setProposalId}) => {
    

    const [modalData, setModalData] = useState({});
    const [invoiceRows, setInvoiceRows] = useState([]);
    const handleClose = () => {
        setProposalId("");
    };

    const getInvoiceDetailsByProposalId = (proposalId) => {
        apis.api.get(`/dashboard/proposals/${proposalId}`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((data) => {
            setModalData(data.data['invoice'][0])
            setInvoiceRows(data.data['items']);
        });
    };
    let gridStyle = {
        margin: "2px"
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
        bgcolor: 'background.paper',
        borderRadius: '15px',
        boxShadow: "0 5px 5px 0 rgba(0,0,0,.25)",
        p: 4,
        overflowY: "auto",
        color: "#333"
    };

    useEffect(() => {
        // API Calls
        getInvoiceDetailsByProposalId(proposalId);
    }, []);

    return (<Modal open={ proposalId ? true : false} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
    <Box sx={style}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseRoundedIcon 
            onClick={handleClose}
            sx={{cursor:'pointer'}}
            />
        </Box>
        <Typography id="modal-modal-title" variant="h4">Invoice Details</Typography>
        <Divider />
        <br />
        <Typography id="modal-modal-title" variant="h5">Customer Information</Typography>
        <Divider />
        <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Name:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData["First Name"] ? modalData["First Name"] : ""} {modalData["Last Name"] ? modalData["Last Name"] : ""}</Typography>
            </Grid>
        </Grid>
        <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Billing Address:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData['Shipping Address Line 1']}
                    {modalData['Shipping Address Line 2']}
                    {modalData['Shipping Address City']}, {modalData['Shipping Address State']}, {modalData['Shipping Address Postal Code']}</Typography>
            </Grid>
        </Grid>
        <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Shipping Address:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData['Billing Address Line 1']}
                    {modalData['Billing Address Line 2']}
                    {modalData['Billing Address City']}, {modalData['Billing Address State']}, {modalData['Billing Address Postal Code']}</Typography>
            </Grid>
        </Grid>
        <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Contact:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData['Email']} | {modalData['Phone']}</Typography>
            </Grid>
        </Grid>
    
        <br />
        <Typography variant="h5">Invoice</Typography>
        <Divider />
        <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Date Issued:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData['Date']}</Typography>
            </Grid>
        </Grid>
        <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Status:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData['Proposal Status']}</Typography>
            </Grid>
        </Grid>
        <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Last Modified:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData['Timestamp']}</Typography>
            </Grid>
        </Grid>
        <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Subtotal:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData['Subtotal']}</Typography>
            </Grid>
        </Grid>
        <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Tax Name:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData['Tax Name']}</Typography>
            </Grid>
        </Grid>
        <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Tax Rate:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData['Tax Rate']}</Typography>
            </Grid>
        </Grid>
        <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Tax Total:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData['Tax Total']}</Typography>
            </Grid>
        </Grid>
        <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Total:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData['Total']}</Typography>
            </Grid>
        </Grid>
        <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Amount Paid:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData['Amount Paid']}</Typography>
            </Grid>
        </Grid>
        {/* <Grid sx={gridStyle} container spacing={2} columns={24}>
            <Grid item xs={8}>
                <Typography><b>Employee Notes:</b></Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography>{modalData['Employee Notes'] ? modalData['Employee Notes'] : "-"}</Typography>
            </Grid>
        </Grid> */}
        <br />
        <Divider />
        <Typography variant="h5">Invoice Items </Typography>
        <Divider />
        <Box>
            <ReactDataGrid
                idProperty="ID"
                columns={invoiceColumns}
                dataSource={invoiceRows}
                theme="green-light"
                columnMinWidth={50} />
        </Box>
    </Box>
    </Modal>);
};