import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { Button } from '@mui/material';
import { SentEmailsModal } from './SentEmailsModal';
import { getSentEmails } from '../../api/invoice-api';

import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from 'moment'
export const SentEmails = ({ token }) => {
    const [rows, setRows] = useState([]);
    const gridStyle = { marginTop: 10, height: '69vh', overflow: 'auto' }
    const [value, setValue] = React.useState('');


    var columns = [
        {
            name: "id",
            header: "ID",
            visible: true,
            defaultWidth: 70,
            type: 'number',
        },
        {
            name: "proposal_id",
            header: "Proposal ID",
            visible: true,
            defaultWidth: 400,
            enableColumnFilterContextMenu: true
        }, {
            name: "email_id",
            header: "Email ID",
            visible: true,
            enableColumnFilterContextMenu: true
        }, {
            name: "email_sent_date",
            header: "Email Sent Date",
            visible: true,
            defaultWidth: 250,
            enableColumnFilterContextMenu: true,
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                return {
                    dateFormat: 'MM-DD-YYYY',
                    cancelButton: false,
                    highlightWeekends: false,
                }
            },
            render: ({ value, cellProps }) => {
                return moment(value).format('MM-DD-YYYY')
            }
        }, {
            name: "subject",
            header: "Subject",
            visible: true,
            defaultWidth: 250,
            enableColumnFilterContextMenu: true
        }, {
            name: "email_body",
            header: "Email Body",
            visible: true,
            defaultWidth: 200,
            enableColumnFilterContextMenu: true,
            render: ({ value, data }) => {
                return (
                    <Button color="primary" variant="contained" onClick={() => setValue(data)}>
                        View Sent Email
                    </Button>)
            }
        },
    ];
    columns = columns.filter(col => col.visible === true);


    const getFormData = () => {
        getSentEmails(token).then(response => {
            const data = response.data;
            setRows(data);
        });
    }

    useEffect(() => {
        // API Calls
        getFormData();
    },[]);

    return (
        <Box sx={{ height: "100%" }}>
            <br />
            <Typography variant="h4" sx={{ flexGrow: 1, p: 1 }}>
                Sent Emails
            </Typography>
            <Divider />
            <>
                {rows ? <ReactDataGrid

                    style={gridStyle}
                    columns={columns}
                    dataSource={rows}
                    pagination
                    theme='green-light'
                    defaultLimit={10}
                    defaultSortInfo={{ name: 'id', dir: -1 }}
                    pageSizes={[10, 25, 50]}
                    enableColumnFilterContextMenu={true}
                /> : ""}
                {value && (<SentEmailsModal token={token} value={value} setValue={setValue} />)}

            </>
        </Box>
    );
};
