import React, { useCallback, useEffect, useState } from 'react';
import apis, { enqueueQuickbookJobs, getAllInvoiceDetails } from "../../api/invoice-api";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { green, red } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Checkbox } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { withStyles } from "@material-ui/core/styles";
import { InvoiceModal } from './InvoiceModal/InvoiceModal';
import { DialogBox } from './DialogBox';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from 'moment'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
window.moment = moment

const CustomColorCheckbox = withStyles({
    root: {
        color: "#1b867b",
        "&$checked": {
            color: "#1b867b"
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

const exported_icons = [<CloseRoundedIcon sx={{ color: red[500] }} />, <CheckRoundedIcon sx={{ color: green[500] }} />];

const defaultSortInfo = { name: 'Date', dir: -1 }


const gridStyle = { marginTop: 10, height: '72vh', overflow: 'auto' }

const filterValue = [
    { name: 'Email', operator: 'startsWith', type: 'string', value: '' },
    { name: 'First Name', operator: 'startsWith', type: 'string', value: '' },
    { name: 'Last Name', operator: 'startsWith', type: 'string', value: '' },
    { name: "Shipping Address Line 1", operator: 'contains', type: 'string', value: '' },
    { name: "Shipping Address Postal Code", operator: 'contains', type: 'string', value: '' },
    { name: "Shipping Address City", operator: 'startsWith', type: 'string', value: '' },
    { name: "Shipping Address State", operator: 'startsWith', type: 'string', value: '' },
    { name: "Phone", operator: 'contains', type: 'string', value: '' },
    { name: "Proposal Status", operator: 'startsWith', type: 'string', value: '' },
    { name: "Proposal Type", operator: 'startsWith', type: 'string', value: '' },
    { name: "Total", type: 'number', operator: 'eq' },
    { name: "Date", operator: 'eq', type: 'date' },
    { name: "Tax Name", operator: 'startsWith', type: 'string', value: '' }

];

export const InvoicePage = ({ token }) => {
    const options = ['Invoice only', 'Non-exported only', 'Hide Jobs before 60 days'];
    const [snackBarState, setSnackBarState] = React.useState({
        openSnackbar: false,
        vertical: 'top',
        horizontal: 'center',
        message: "Successful !",
        severity: "success"
    });
    const { vertical, horizontal, openSnackbar, message, severity } = snackBarState;
    const [rows, setRows] = useState([]);
    const [description, setDescription] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [totalData, setTotalData] = useState([]);
    const [proposalId, setProposalId] = useState("");
    const [selected, setSelected] = useState({});
    const onSelectionChange = useCallback(event => {
        if (event.data) {
            let temp = {}
            if (Array.isArray(event.data)) {
                for (let singleSelect of event.data) {
                    temp[singleSelect['Proposal ID']] = singleSelect;
                }
            } else {
                temp = event.selected;
            }
            setSelected(temp);
        }

    }, []);

    const CheckBox = ({ label, value, onChange }) => {
        return (
            <label>
                <CustomColorCheckbox checked={value} onChange={onChange} />
                {label}
            </label>
        );
    };

    const handleModalOpen = (id) => {
        setProposalId(id);
    };

    const handleConfirmationDialog = () => {
        setDescription(`You are about to enqueue: (${Object.keys(selected).length}) jobs to Quickbooks.`);
        setOpenDialog(true);
    };

    const handleSnackbarClose = () => {
        setSnackBarState({ ...snackBarState, openSnackbar: false });
    };

    const handleEnqueueConfirmation = () => {
        setIsLoaded(loading => !loading);
        let invoiceIds = Object.keys(selected);
        var json = []
        for (let singleInvoice of invoiceIds) {
            json.push({ 'proposal_id': singleInvoice, 'enqueue_time': new Date() });
        }
        enqueueQuickbookJobs(token, json).then(response => {
            setSnackBarState({ ...snackBarState, openSnackbar: true, message: "Successfully enqueued jobs !", severity: "success" });
            getInvoices();
        }).catch((err) => {
            setSnackBarState({ ...snackBarState, openSnackbar: true, message: "There was an error enqueuing jobs.", severity: "error" });
        });
        setOpenDialog(false);
        setIsLoaded(loading => !loading);
    };




    const getInvoices = () => {
        getAllInvoiceDetails(token).then(response => {
            setRows([]);
            setTotalData([]);
            let data = response.data;
            for (var index = 0; index < data.length; index++) {
                data[index]['Shipping Address Line 1'] += " " + data[index]['Shipping Address Line 2'];
                data[index]['Date'] = data[index]['Date'].slice(0, 10);
            }
            setTotalData(data);
            let res = [];
            for (let index = 0; index < data.length; index++) {
                if (calcDate(data[index]['Date']) <= 60) {
                    res.push(data[index])
                }
            }
            setRows(res);
        });
    }

    useEffect(() => {
        // API Calls
        getInvoices();
    }, []);

    var columns = [{
        name: "Proposal ID",
        header: "View",
        visible: true,
        defaultWidth: 80,
        render: ({ value }) => {
            return <SearchRoundedIcon
                onClick={() => { handleModalOpen(value); }}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#1b867b',
                        borderColor: '#1b867b'
                    },
                }}
            />
        },
        enableColumnFilterContextMenu: false
    }, {
        name: "Activity ID",
        header: "Activity ID",
        visible: false
    }, {
        name: "QB_EXPORTED",
        header: "Exported",
        visible: true,
        defaultWidth: 100,
        render: ({ value }) => {
            return exported_icons[value] ? exported_icons[value] : 'unknown'
        }
    }, {
        name: "First Name",
        header: "First Name",
        visible: true
    }, {
        name: "Last Name",
        header: "Last Name",
        visible: true
    }, {
        name: "Shipping Address Line 1",
        header: "Address",
        visible: true,
        enableColumnFilterContextMenu: true
    }, {
        name: "Shipping Address Postal Code",
        header: "Zipcode",
        visible: true,
        enableColumnFilterContextMenu: true
    }, {
        name: "Shipping Address City",
        header: "City",
        visible: true,
        enableColumnFilterContextMenu: true
    }, {
        name: "Shipping Address State",
        header: "State",
        visible: true,
        enableColumnFilterContextMenu: true
    }, {
        name: "Phone",
        header: "Phone Number",
        visible: true,
        enableColumnFilterContextMenu: true
    }, {
        name: "Email",
        header: "Email",
        visible: true,
        enableColumnFilterContextMenu: true
    }, {
        name: "Shipping Address Line 2",
        header: "Shipping Address Line 2",
        visible: false
    }, {
        name: "Billing Address Line 1",
        header: "Billing Address Line 1",
        visible: false
    }, {
        name: "Billing Address Line 2",
        header: "Billing Address Line 2",
        visible: false
    }, {
        name: "Billing Address City",
        header: "Billing Address City",
        visible: false
    }, {
        name: "Billing Address Postal Code",
        header: "Billing Address Postal Code",
        visible: false
    }, {
        name: "Proposal Name",
        header: "Proposal Name",
        visible: false
    }, {
        name: "Proposal Status",
        header: "Status",
        visible: true,
        enableColumnFilterContextMenu: true
    }, {
        name: "Proposal Type",
        header: "Type",
        visible: true,
        enableColumnFilterContextMenu: true
    }, {
        name: "Proposal Source",
        header: "Proposal Source",
        visible: false
    }, {
        name: "Proposal Number",
        header: "Proposal Number",
        visible: false
    }, {
        name: "Date",
        header: "Date",
        visible: true,
        enableColumnFilterContextMenu: true,
        filterEditor: DateFilter,
        filterEditorProps: (props, { index }) => {
            return {
                dateFormat: 'MM-DD-YYYY',
                cancelButton: false,
                highlightWeekends: false,
            }
        },
        render: ({ value, cellProps }) => {
            return moment(value).format('MM-DD-YYYY')
        }
    }, {
        name: "Time",
        header: "Time",
        visible: false
    }, {
        name: "Subtotal",
        header: "Subtotal",
        type: 'number',
        visible: false
    }, {
        name: "Tax Total",
        header: "Tax Total",
        type: 'number',
        visible: false
    }, {
        name: "Total",
        header: "Total",
        visible: true,
        type: 'number',
        enableColumnFilterContextMenu: true,
        filterEditor: NumberFilter
    }, {
        name: "Tax Name",
        header: "Tax Name",
        visible: true,
        enableColumnFilterContextMenu: true
    }, {
        name: "Tax Rate",
        header: "Tax Rate",
        visible: false
    }, {
        name: "Number Of Items",
        header: "Number Of Items",
        visible: false
    }, {
        name: "Customer Notes",
        header: "Customer Notes",
        visible: false
    }, {
        name: "completed",
        header: "completed",
        visible: false
    }, {
        name: "dropbox_url",
        header: "Dropbox",
        visible: true,
        defaultWidth: 100,
        render: ({ value }) => {
            return value ? <a href={value} target="_blank">Link</a> : ""
        }
    },
    ];
    columns = columns.filter(col => col.visible === true);



    const [loading, setIsLoaded] = useState(false);
    const [loading_routzy, setIsLoadedRoutzy] = useState(false);
    const [checkedState, setCheckedState] = useState([false, false, true]);

    function calcDate(a) {
        const curr_date = Date.now();
        var api_date = new Date(a);
        var current = new Date(curr_date);
        return (Math.floor((current - api_date) / (1000 * 60 * 60 * 24)));
    }
    const handleChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) => index === position ? !item : item);
        let res = [];
        setCheckedState(updatedCheckedState);

        if (updatedCheckedState[0] && updatedCheckedState[1] && updatedCheckedState[2]) {
            for (let index = 0; index < totalData.length; index++) {
                if (totalData[index]['Proposal Type'] === "Invoice" && totalData[index]['QB_EXPORTED'] !== 1 && calcDate(totalData[index]['Date']) <= 60) {
                    res.push(totalData[index])
                }
            }
            setRows(res);
        } else if (updatedCheckedState[0] && updatedCheckedState[1] && !updatedCheckedState[2]) {
            for (let index = 0; index < totalData.length; index++) {
                if (totalData[index]['Proposal Type'] === "Invoice" && totalData[index]['QB_EXPORTED'] !== 1) {
                    res.push(totalData[index])
                }
            }
            setRows(res);

        } else if (updatedCheckedState[0] && !updatedCheckedState[1] && !updatedCheckedState[2]) {
            for (let index = 0; index < totalData.length; index++) {
                if (totalData[index]['Proposal Type'] === "Invoice") {
                    res.push(totalData[index])
                }
            }
            setRows(res);

        } else if (!updatedCheckedState[0] && updatedCheckedState[1] && updatedCheckedState[2]) {
            for (let index = 0; index < totalData.length; index++) {
                if (calcDate(totalData[index]['Date']) <= 60 && totalData[index]['QB_EXPORTED'] !== 1) {
                    res.push(totalData[index])
                }
            }
            setRows(res);
        } else if (!updatedCheckedState[0] && updatedCheckedState[1] && !updatedCheckedState[2]) {
            for (let index = 0; index < totalData.length; index++) {
                if (totalData[index]['QB_EXPORTED'] !== 1) {
                    res.push(totalData[index])
                }
            }
            setRows(res);

        } else if (!updatedCheckedState[0] && !updatedCheckedState[1] && updatedCheckedState[2]) {
            for (let index = 0; index < totalData.length; index++) {
                if (calcDate(totalData[index]['Date']) <= 60) {
                    res.push(totalData[index])
                }
            }
            setRows(res);

        } else if (updatedCheckedState[0] && !updatedCheckedState[1] && updatedCheckedState[2]) {
            for (let index = 0; index < totalData.length; index++) {
                if (calcDate(totalData[index]['Date']) <= 60 && totalData[index]['Proposal Type'] === "Invoice") {
                    res.push(totalData[index])
                }
            }
            setRows(res);

        } else {
            for (let index = 0; index < totalData.length; index++) {
                res.push(totalData[index])
            }
            setRows(totalData);
        }


    };

    const fetchData = () => {
        setIsLoaded(loading => !loading);
        apis.api.get(`/dashboard/routzy/sync`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((data) => {
            getInvoices();
            setIsLoaded(loading => !loading);
        }).catch((error) => {
            // console.log(error);
            setIsLoaded(loading => !loading);
        })
    };
    return (
        <Box >
            <Snackbar anchorOrigin={{ vertical, horizontal }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                key={vertical + horizontal}>
                <Alert onClose={handleSnackbarClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            // onClick={handleCloseBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Typography variant="h4" sx={{ flexGrow: 1, p: 1 }}>
                Invoices
            </Typography>
            <Divider />
            <Box>
                {options.map((option, index) => {
                    return (
                        <label key={index}>
                            <CheckBox
                                label={option}
                                value={checkedState[index]}
                                onChange={() => handleChange(index)}
                            />
                        </label>
                    );

                }
                )}
                <LoadingButton
                    onClick={handleConfirmationDialog}
                    loading={loading_routzy}
                    loadingIndicator="Loading..."
                    variant="contained"
                    sx={{
                        backgroundColor: '#1b867b',
                        color: '#ffffff',
                        '&:hover': {
                            backgroundColor: '#136a61',
                            color: '#fff',
                            borderColor: '#1b867b'
                        },
                        ml: 3
                    }}
                >
                    Quickbooks
                </LoadingButton>
                <LoadingButton
                    onClick={fetchData}
                    loading={loading}
                    loadingIndicator="Loading..."
                    variant="contained"
                    sx={{
                        backgroundColor: '#e85656',
                        color: 'white',
                        '&:hover': {
                            color: '#fff',
                            backgroundColor: '#d03e3e',
                            borderColor: '#d03e3e'
                        },
                        ml: 3
                    }}
                >
                    Routzy Sync
                </LoadingButton>
            </Box>
            {proposalId !== "" && (<InvoiceModal token={token} proposalId={proposalId} setProposalId={setProposalId} />)}

            <Box >
                {rows ? <ReactDataGrid
                    idProperty="Proposal ID"
                    style={gridStyle}
                    columns={columns}
                    dataSource={rows}
                    pagination
                    theme='green-light'
                    selected={selected}
                    onSelectionChange={onSelectionChange}
                    defaultLimit={10}
                    defaultFilterValue={filterValue}
                    defaultSortInfo={defaultSortInfo}
                    checkboxColumn
                    deletebutton
                    pageSizes={[10, 25, 50]}
                    enableColumnFilterContextMenu={true}
                    getSelectedMap

                // sortModel={sortModel}
                // onSortModelChange={(model) => setSortModel(model)}
                /> : ""}
            </Box>

            <DialogBox
                open={openDialog}
                setOpen={setOpenDialog}
                description={description}
                handleEnqueueConfirmation={handleEnqueueConfirmation}
            />
        </Box>
    )
}
