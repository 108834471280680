import './Drawer.scss';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import PermPhoneMsgRoundedIcon from '@mui/icons-material/PermPhoneMsgRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import WorkIcon from '@mui/icons-material/Work';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { useAuth0 } from "@auth0/auth0-react";
import MapIcon from '@mui/icons-material/Map';
import Backdrop from '@mui/material/Backdrop';
import { useEffect, useState } from 'react';
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import apis from "../api/invoice-api";

const drawerWidth = 220;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100%)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        backgroundColor: 'blue',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);



export function MiniDrawer({ MainContentComponent, usecomponentIndex, isAuthenticated, allcomponents, role, setRole }) {

    let history = useNavigate();


    const { getIdTokenClaims } = useAuth0();

    const [open, setOpen] = React.useState(false);
    const [token, setToken] = React.useState(process.env.REACT_APP_ENV === 'production' ? '' : 'N/A');

    const [roles, setRoles] = React.useState([]);
    const [compindex, setCompIndex] = React.useState(0);


    const routzySync = () => {
        if(process.env.REACT_APP_ENV === 'staging')
            return;
        apis.api.get(`/dashboard/routzy/sync`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((data) => {
            // console.log("Routzy sync completed: ",data);
        }).catch((error) => {
            // console.log(error);
        })
    }
    const callSecureApi = async () => {
        console.log(role);
        if (process.env.REACT_APP_ENV === 'staging') {
            setRoles([role]);
            if (role === "Crew")
                usecomponentIndex(3);
            else
                usecomponentIndex(0);
            routzySync();

        } else {
            const token = await getIdTokenClaims();
            if (token) {
                setToken(token.__raw);
            }
            //start
            const decodedHeader = jwt_decode(token.__raw);

            let role = decodedHeader["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

            setRoles(decodedHeader["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]);

            if (role && role.length === 0) {
                history('/404');

            }

            if (role.includes("Crew"))
                usecomponentIndex(3);




        }
    };

    useEffect(() => {
        document.title = "LifeTreeWNY";
        // if (isAuthenticated && process.env.REACT_APP_ENV === 'production') {
        callSecureApi();
        // }
    }, []);

    useEffect(() => {
        routzySync();
    }, [token])

    const { logout } = useAuth0();

    const menuItems = [{
        'id': 1,
        'header': 'Invoices',
        'icon': <InboxIcon />,
        // 'icon': <ReceiptRoundedIcon />,
        'roles': [
            'Manager'
        ],
        'Component': 'InvoicePage',
    }, {
        'id': 2,
        'header': 'Quickbooks',
        'icon': <MonetizationOnRoundedIcon />,
        'roles': [
            'Manager'
        ],
        'Component': 'QuickbooksPage'
    },
    {
        'id': 3,
        'header': 'Contact Form',
        'icon': <PermPhoneMsgRoundedIcon />,
        'roles': [
            'Manager'
        ],
        'Component': 'ContactForms'
    },
    {
        'id': 4,
        'header': 'Jobs List',
        'icon': <WorkIcon />,
        'roles': [
            'Manager',
            'Crew'
        ],
        'Component': 'CrewLogin'
    }/* ,
    {
        'id': 5,
        'header': 'Email Scheduling',
        "icon": <ForwardToInboxIcon />,
        'roles': [
            'Manager'
        ],
        'Component': 'EmailScheduling'
    } */,
    {
        'id': 6,
        'header': 'Map View',
        'icon': <MapIcon />,
        'roles': [
            'Manager',
            'Crew'
        ],
        'Component': 'Map',
    },
    {
        'id': 7,
        'header': 'Email Builder',
        'icon': <ForwardToInboxIcon />,
        'roles': [
            'Manager'
        ],
        'Component': 'EmailBuilder'
    }
    ];

    const handleDrawerOpen = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };


    const getIndex = (index) => {
        usecomponentIndex(index);
    }


    return (

        <Box sx={{ backgroundColor: '#efefef', minHeight: 'auto', }} >
            <CssBaseline />
            <AppBar position="fixed" open={open} style={{ background: "#1C2B36" }}>
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        color="inherit"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: '36px'
                        }}
                    >
                        {!open && <MenuIcon />}
                        {open && <CloseIcon />}
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <span className="appbar-title-life">Life</span>
                        <span className="appbar-title-treewny">TreeWNY</span>
                    </Typography>
                    {process.env.REACT_APP_ENV === 'staging' ? (
                        <Button sx={{ color: 'white' }} onClick={() => setRole("")}>
                            Logout
                        </Button>
                    ) : (<Button sx={{ color: 'white' }} onClick={() => logout({ returnTo: process.env.REACT_APP_BASE_URL })}>
                        Logout
                    </Button>)}
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                variant="permanent"
                open={open}
                onClose={() => setOpen(false)}
                style={{ background: "#1C2B36" }}
            >
                <DrawerHeader textalign="center" p={2} width={20}>
                    Components
                </DrawerHeader>
                <Divider />
                <List>
                    {
                        roles.map((r) =>
                            menuItems.map((page, index) => {

                                return (

                                    page.roles.includes(r) &&
                                    (<ListItem key={index} button onClick={() => {
                                        usecomponentIndex(index); setOpen(false);
                                    }}>
                                        {open ? (<ListItemIcon>
                                            {page.icon}
                                        </ListItemIcon>) : (<Tooltip title={page.header} placement="right">
                                            <ListItemIcon>
                                                {page.icon}
                                            </ListItemIcon>
                                        </Tooltip>)}

                                        <ListItemText primary={page.header} />
                                    </ListItem>)

                                )
                            }
                            )
                        )}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, ml: 9, mt: 6.5, p: 2, backgroundColor: '#efefef',height: '100%' }}>
                {token && <MainContentComponent token={token}/>}

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                    open={open}
                >
                </Backdrop>
            </Box>
        </Box>
    );
}
