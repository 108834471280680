import React, { useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 350,
        },
    },
};


export const CustomSingleSelect = (
    {
        optionList,
        value,
        errors,
        label,
        id,
        name,
        sx,
        onChange,
        ...inputProps }) => {

    const [options, setOptions] = React.useState(value);


    const onHandleChange = (event) => {
        const {
            target: { value },
        } = event;

        setOptions(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        onChange(event);
    };
    // useEffect(() => {
    //     console.log(value);
    // }, [])

    const theme = useTheme();
    function getStyles(name, option, theme) {

        return {
            fontWeight:
                option.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    return (
        <FormControl sx={sx?sx:{ m: 1, maxWidth: '100%', minWidth: '100%', mt: 2 }}>
            <InputLabel id={id + "-label"}>{label}</InputLabel>
            <Select
                id={id}
                value={value}
                onChange={onHandleChange}
                input={<OutlinedInput label={label} />}
                MenuProps={MenuProps}
                name={name}
            >
                {optionList.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        style={getStyles(option.name, option.name, theme)}
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}