import React, { useEffect, useState } from 'react';
import { getQuickbookQueueData, deleteQb, enqueueQuickbookJobs, updateQbPath, getQbPath } from "../../api/invoice-api";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { green, red } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Checkbox } from '@mui/material';
import { withStyles } from "@material-ui/core/styles";
import { QuickbooksModal } from './QuickbooksModal/QuickbooksModal';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { FormInput } from '../../components/FormInput';
import { useFormik } from 'formik';
import { Row, Col, Form } from "reactstrap";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomColorCheckbox = withStyles({
    root: {
        color: "#1b867b",
        "&$checked": {
            color: "#1b867b"
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

const exported_icons = [<CloseRoundedIcon sx={{ color: red[500] }} />, <CheckRoundedIcon sx={{ color: green[500] }} />];


const gridStyle = { minHeight: 570, marginTop: 10 }

const filterValue = [
    { name: 'Email', operator: 'startsWith', type: 'string', value: '' },
    { name: 'First Name', operator: 'startsWith', type: 'string', value: '' },
    { name: 'Last Name', operator: 'startsWith', type: 'string', value: '' },
    { name: "Proposal Status", operator: 'startsWith', type: 'string', value: '' },
    { name: "Proposal Type", operator: 'startsWith', type: 'string', value: '' },
    { name: "Total", operator: 'gte', type: 'number', value: 0 },
    { name: "Date", operator: 'contains', type: 'string', value: '' },
    { name: "status", operator: 'startsWith', type: 'string', value: '' },
    { name: "code", operator: 'startsWith', type: 'string', value: '' },
    { name: "enqueue_time", operator: 'contains', type: 'string', value: '' },
    { name: "dequeue_time", operator: 'contains', type: 'string', value: '' },
    { name: "msg", operator: 'contains', type: 'string', value: '' }

];

export const QuickbooksPage = ({ token }) => {
    const [rows, setRows] = useState([]);
    const [proposalId, setProposalId] = useState("");
    const [totalData, setTotalData] = useState([]);
    const handleModalOpen = (id) => {
        //console.log(id);
        setProposalId(id);
    };
    const [snackBarState, setSnackBarState] = useState({
        openSnackbar: false,
        vertical: 'top',
        horizontal: 'center',
        message: "Successful !",
        severity: "success"
    });
    const { vertical, horizontal, openSnackbar, message, severity } = snackBarState;
    const CheckBox = ({ label, value, onChange }) => {
        return (
            <Row>
                <Col sm="2">
                    <CustomColorCheckbox sx={{ mt: 4, pt:1 }} checked={value} onChange={onChange} />
                </Col>
                <Col sm="10">
                    <Typography sx={{ pt: 5 }} >
                        {label}
                    </Typography>
                </Col>
            </Row>
        );
    };

    const [initialValues, setInitialValues] = React.useState({
        path: ''
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,

        onSubmit: (values) => {


            let formObject = {
                path: values.path
            }

            console.log(formObject);
            updateQbPath(token, formObject).then(response => {
                setSnackbarByStatus(response)
            });
        }
    });


    const setSnackbarByStatus = (response) => {
        if (response.status === 200) {
            setSnackBarState({ ...snackBarState, openSnackbar: true, message: "QB Path Updated Successfully !", severity: "success" });
        }
        else {
            setSnackBarState({ ...snackBarState, openSnackbar: true, message: "Error updating QB Path !", severity: "error" });
        }
    }

    const getQueueData = () => {
        getQuickbookQueueData(token).then(response => {
            setRows([]);
            setTotalData([]);
            const data = response.data;
            for (var index = 0; index < data.length; index++) {
                if (data[index]['code'] === 'e') {
                    data.row.classList.add('colore')
                }
                data[index]['Shipping Address Line 1'] += " " + data[index]['Shipping Address Line 2'];
                data[index]['Date'] = data[index]['Date'].slice(0, 10);
            }
            setRows(data);
            setTotalData(data);
        });

        getQbPath(token).then(response => {
            if (response) {
                setInitialValues(response.data[0])
            }
        })
    }

    useEffect(() => {
        // API Calls
        getQueueData();
    }, []);

    const deleteQB = (event, record) => {
        //console.log(record);
        deleteQb(token, { proposal_id: record['Proposal ID'], id: record['id'] }).then(response => {
            if (response.status === 200) {
                setSnackBarState({ ...snackBarState, openSnackbar: true, message: "Deleted Job Successful !", severity: "success" });
                getQueueData();
            }
            else
                setSnackBarState({ ...snackBarState, openSnackbar: true, message: "Error deleting job !", severity: "error" });
        });
    };


    const reenqueueQB = (event, record) => {
        //console.log(record);
        // apis.api.post('/dashboard/quickbooks/queue/delete', {
        //     data: record.proposalId
        // }).then((data) => {

        // }).catch((error) => {
        //     //console.log(error);
        // })
        // let invoiceIds = Object.keys(selected);
        // record.enqueue_time=record.enqueue_time.slice(0, 10) + " " + record.enqueue_time.slice(11, 19);
        enqueueQuickbookJobs(token, [{ proposal_id: record['Proposal ID'] }]).then(response => {
            if (response.status === 200) {
                setSnackBarState({ ...snackBarState, openSnackbar: true, message: "Re-enqueued Job Successful !", severity: "success" });
                getQueueData();
            }
            else
                setSnackBarState({ ...snackBarState, openSnackbar: true, message: "Re-enqueued Job Successful !", severity: "error" });
            //console.log(response);
        });
    };



    const handleSnackbarClose = () => {
        setSnackBarState({ ...snackBarState, openSnackbar: false });
    };

    var columns = [{
        name: "Proposal ID",
        header: "View",
        visible: true,
        defaultWidth: 80,
        render: ({ value }) => {
            return <SearchRoundedIcon
                onClick={() => { handleModalOpen(value); }}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#1b867b',
                        borderColor: '#1b867b'
                    },
                }}
            />
        },
        enableColumnFilterContextMenu: false
    }, {
        name: "Activity ID",
        header: "Activity ID",
        visible: false
    }, {
        name: "QB_EXPORTED",
        header: "Exported",
        visible: false,
        defaultWidth: 100,
        render: ({ value }) => {
            return exported_icons[value] ? exported_icons[value] : 'unknown'
        }
    }, {
        name: "First Name",
        header: "First Name",
        visible: true
    }, {
        name: "Last Name",
        header: "Last Name",
        visible: true
    }, {
        name: "Shipping Address Line 1",
        header: "Address",
        visible: false,
        enableColumnFilterContextMenu: true
    }, {
        name: "Shipping Address Postal Code",
        header: "Zipcode",
        visible: false,
        enableColumnFilterContextMenu: true
    }, {
        name: "Shipping Address City",
        header: "City",
        visible: false,
        enableColumnFilterContextMenu: true
    }, {
        name: "Shipping Address State",
        header: "State",
        visible: false,
        enableColumnFilterContextMenu: true
    }, {
        name: "Phone",
        header: "Phone Number",
        visible: false,
        enableColumnFilterContextMenu: true
    }, {
        name: "Email",
        header: "Email",
        visible: false,
        enableColumnFilterContextMenu: true
    }, {
        name: "Shipping Address Line 2",
        header: "Shipping Address Line 2",
        visible: false
    }, {
        name: "Billing Address Line 1",
        header: "Billing Address Line 1",
        visible: false
    }, {
        name: "Billing Address Line 2",
        header: "Billing Address Line 2",
        visible: false
    }, {
        name: "Billing Address City",
        header: "Billing Address City",
        visible: false
    }, {
        name: "Billing Address Postal Code",
        header: "Billing Address Postal Code",
        visible: false
    }, {
        name: "Proposal Name",
        header: "Proposal Name",
        visible: false
    }, {
        name: "Proposal Status",
        header: "Status",
        visible: false,
        enableColumnFilterContextMenu: true
    }, {
        name: "Proposal Type",
        header: "Proposal Type",
        visible: true,
        enableColumnFilterContextMenu: true
    }, {
        name: "Total",
        header: " Job Total",
        visible: true,
        type: 'number',
        enableColumnFilterContextMenu: true
    }, {
        name: "Proposal Source",
        header: "Proposal Source",
        visible: false
    }, {
        name: "Proposal Number",
        header: "Proposal Number",
        visible: false
    }, {
        name: "Date",
        header: "Job Date",

        visible: true,
        enableColumnFilterContextMenu: true
    }/*, {
        name: "status",
        header: "Status",
        visible: true,
        defaultWidth: 90,
        enableColumnFilterContextMenu: true
    }, {
        name: "code",
        header: "Code",
        defaultWidth: 80,
        visible: true,
        enableColumnFilterContextMenu: true
    } , {
        name: "enqueue_time",
        header: "enqueue_time",
        visible: true,

        render: ({ data }) => {
            return <Box

                sx={{
                    whiteSpace: 'normal'
                }}>{data.enqueue_time}</Box>

        },

        enableColumnFilterContextMenu: true
    }, {
        name: "dequeue_time",
        header: "dequeue_time",
        visible: true,
        enableColumnFilterContextMenu: true,
        render: ({ data }) => {
            return <Box

                sx={{
                    whiteSpace: 'normal'
                }}>{data.enqueue_time}</Box>

        },
        // resizable: true,

    } */, {
        name: "msg",
        header: "Message",
        visible: true,
        enableColumnFilterContextMenu: true,
        cellProps: {
            style: {
                color: 'white'
            }
        },
        render: ({ value }) => {
            return <Tooltip title={value}><Box>{value}</Box></Tooltip>
        },
        onRender: (cellProps, { data }) => {
            cellProps.style.background = data.msg === "Status OK" ? '#9ACD32' : '#FFCA33'
        },



    }, {
        name: "action",
        header: "Action",
        defaultWidth: 90,
        visible: true,
        render: ({ data }) => {
            // //console.log(data);
            if (data.status === 'q')
                return <Button sx={{ cursor: 'pointer', backgroundColor: red[500], color: 'white' }} onClick={(event) => deleteQB(event, data)} >Delete</Button>;
            else
                return <RefreshIcon variant="outlined"
                    onClick={(event) => reenqueueQB(event, data)}
                    sx={{
                        cursor: 'pointer', backgroundColor: '#008B8B', color: 'white', borderRadius: '10px'
                    }} />;
        },


    }, {
        name: "Time",
        header: "Time",
        visible: false
    }, {
        name: "Subtotal",
        header: "Subtotal",
        type: 'number',
        visible: false
    }, {
        name: "Tax Total",
        header: "Tax Total",
        type: 'number',
        visible: false
    }, {
        name: "Tax Name",
        header: "Tax Name",
        visible: false,
        enableColumnFilterContextMenu: true
    }, {
        name: "Tax Rate",
        header: "Tax Rate",
        visible: false
    }, {
        name: "Number Of Items",
        header: "Number Of Items",
        visible: false
    }, {
        name: "Customer Notes",
        header: "Customer Notes",
        visible: false
    },
    {
        name: "completed",
        header: "completed",
        visible: false
    },
    ];
    columns = columns.filter(col => col.visible === true);

    const [checkedState, setCheckedState] = useState(new Array(1).fill(false));
    const handleChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) => index === position ? !item : item);
        setCheckedState(updatedCheckedState);
        let res = [];
        if (updatedCheckedState[0]) {
            for (let index = 0; index < totalData.length; index++) {
                if (totalData[index]['QB_EXPORTED'] !== 1) {
                    res.push(rows[index])
                }
            }
            setRows(res);
        } else {
            setRows(totalData)
        }



    };

    return (
        <Box >
            <Snackbar anchorOrigin={{ vertical, horizontal }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                key={vertical + horizontal}>
                <Alert onClose={handleSnackbarClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Typography variant="h4" sx={{ flexGrow: 1, p: 1 }}>
                Quickbooks
            </Typography>
            <Divider />
            <Box>
                <Row>
                    <Col sm="3">
                        <CheckBox
                            sx={{ mt: 5 }}
                            label='Non-exported only'
                            value={checkedState[0]}
                            onChange={() => handleChange(0)}
                        />
                    </Col>
                    <Col sm="9">

                        <Form onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col sm="8">
                                    <FormInput
                                        id="path"
                                        name="path"
                                        label="QB Path"
                                        onChange={formik.handleChange}
                                        value={formik.values.path}
                                        helperText={formik.touched.path && formik.errors.path}
                                        error={formik.touched.path && Boolean(formik.errors.path)}
                                    />
                                </Col>
                                <Col sm="4">

                                    <Button color="primary" variant="contained" type="submit" sx={{ mt: 5 }}>
                                        Save QB Path
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Box>
            <Box>


            </Box>
            {proposalId !== "" && (<QuickbooksModal token={token} proposalId={proposalId} setProposalId={setProposalId} />)}

            <Box>

                {rows ? <ReactDataGrid
                    idProperty="Proposal ID"
                    style={gridStyle}
                    columns={columns}
                    theme='green-light'
                    //  minRowHeight={90}
                    // rowHeight={null}
                    dataSource={rows}
                    pagination
                    defaultLimit={10}
                    defaultFilterValue={filterValue}
                    //  checkboxColumn
                    pageSizes={[10, 25, 50]}
                    enableColumnFilterContextMenu={true}
                /> : ""}

            </Box>
        </Box>
    )
}
