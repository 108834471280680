import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {addEmailTemplate, fetchEmailTemplate, updateEmailTemplate} from "../../api/invoice-api";
import {script as re} from "quill";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Button from "@mui/material/Button";
import { AntSwitch, Stack } from '@mui/material';



class EmailTemplate extends Component {
    message1;
    constructor(props) {
        super(props);
        this.state = {
            jobType: '',
            message: '',
            templates: [],
            jobTypes: ['cabling'],
            dateRange: '',
            subject: ''
        };
        this.handleJobTypeChange = this.handleJobTypeChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleView = this.handleView.bind(this);
        this.modules = {
            toolbar: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'align': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image', 'video'],
                ['clean']
            ]
        };
        this.formats = [
            'header',
            'bold', 'italic', 'underline', 'strike',
            'color', 'background',
            'align',
            'list', 'bullet',
            'link', 'image', 'video'
        ];
    }

    async handleJobTypeChange(event) {
        const jobType = event.target.value;
        let message = '';

        fetchEmailTemplate(this.props.token, jobType).then((data) => {
            //console.log(data);
            message = data;
            this.setState({message: data.data.template_body});
            this.setState({subject: data.data.template_subject});
        }).catch((error) => {
            this.setState({message: ''});
            this.setState({subject: ''});
        })

        // Update the state with the selected jobType and fetched message
        this.setState({ jobType, message });
    }

    handleMessageChange(value) {
        this.setState({ message: value });
    }


    handleSubmit(event) {
        event.preventDefault();
        this.handleAdd();
    }

    handleAdd() {
        const { jobType, message , dateRange, subject} = this.state;
        let textMessage = message.slice(3);
        textMessage = textMessage.slice(0,textMessage.length-4);
        //if ((textMessage.includes(("{~FirstName~}")) || (textMessage.includes("{~LastName~}") ) )&& (subject.includes(("{~FirstName~}")) (subject.includes("{~LastName~}") ) )){
            const payload = {
                job_type: jobType,
                template_subject: subject,
                template_body: textMessage
            }

            addEmailTemplate(this.props.token, payload)
                .then(data => {
                    //console.log(data);
                    this.setState({templates: [...this.state.templates, {jobType, message, dateRange}]});
                })
                .catch(error => console.error(error));
    }


    handleSubjectChange = (event) => {
        this.setState({ subject: event.target.value });
    }
    handleView(jobType) {
        const template = this.state.templates.find(template => template.jobType === jobType);

        fetchEmailTemplate(this.props.token, jobType)
            .then(data => {
                console.log('frontend');
                //console.log(data);
                //this.setState({ templates: [...this.state.templates, { jobType}] });
                this.setState({message: data.data.template_body, 
                                subject: data.data.template_subject});
            })
            .catch(error => console.error(error));
        alert(template.message);
    }
    
    handleUpdate = () => {
        //const template = this.state.templates.find(template => template.jobType === jobType);
        console.log(this.state);
        const { jobType, message, subject } = this.state;
        let textMessage = message.slice(3);
        textMessage = textMessage.slice(0,textMessage.length-4);
        const payload = {
            job_type: jobType,
            template_subject:subject,
            template_body: textMessage
        }
        updateEmailTemplate(this.props.token, payload)
            .then(data => {
                //console.log(data);
                this.setState({ templates: [...this.state.templates, { jobType}] });
            })
            .catch(error => console.error(error));
    }
    async handleDateRangeChange (event) {

        const dateRange = event.target.value;
        let message = '';

        // Fetch the message from the database based on the selected DateRange
        try {
            const response = await fetch(`/api/messages/${dateRange}`);
            const data = await response.json();
            message = data.message;
        } catch (error) {
            console.error(error);
        }

        // Update the state with the selected jobType and fetched message
        this.setState({ dateRange, message });
    }

    render() {
        const { jobType, message, templates,dateRange,subject } = this.state;
        const jobTypes = []; // add more job types here if needed
        // const jobTypeTemplates = templates.filter(template => template.jobType === jobType);
         const jobTypeTemplates = templates.filter(template => template.jobType === jobType && template.dateRange === dateRange);


        return (
            <div>
                <div style={{border: '1px solid #ccc', padding: '20px', borderRadius: '5px'}}>
                    <div style={{marginBottom: '10px'}}>
                        <label style={{fontWeight: 'bold', marginRight: '10px'}}>Job Type:</label>
                        <select value={jobType} onChange={this.handleJobTypeChange} style={{padding: '5px'}}>
                            <option value="Existing Customers">JobType for Existing Customers</option>
                            <option value="consultation">Consultation</option>
                            <option value="consulting">Consulting</option>
                            <option value="cut & leave debris">Cut & Leave Debris</option>
                            <option value="daily rate">Daily Rate</option>
                            <option value="debris removal">Debris Removal</option>
                            <option value="debris removal(hauling debris)">Debris Removal(hauling debris)</option>
                            <option value="tree trimming">Tree Trimming</option>
                            <option value="tree treatment">Tree Treatment</option>
                            <option value="tree removal">Tree Removal</option>
                            <option value="tree trimming class 1 fine pruning">Tree Trimming Class 1 Fine Pruning</option>
                            <option value="tree trimming class II standard pruning">Tree Trimming Class II Standard Pruning</option>
                            <option value="tree trimming class II fine pruning">Tree Trimming Class II Fine Pruning</option>
                            <option value="tree trimming class III hazard pruning">Tree Trimming Class III Hazard Pruning</option>
                            <option value="tree trimming class III fine pruning">Tree Trimming Class III Fine Pruning</option>
                            <option value="tree trimming class IV crown reduction">Tree Trimming Class IV Crown Reduction</option>
                            <option value="tree trimming class IV fine pruning">Tree Trimming Class IV Fine Pruning</option>
                            <option value="tree trimming I">Tree Trimming I</option>
                            <option value="other">Other</option>
                        </select>
                    </div>

                    <div style={{marginBottom: '10px'}}>

                        <div style={{marginBottom: '10px'}}>
                            <label style={{fontWeight: 'bold', marginRight: '10px'}}>Date Range:</label>
                            {/*<input type="text" value={this.state.dateRange} onChange={this.handleDateRangeChange} style={{padding: '5px'}} />*/}
                            <select value={jobType} onChange={this.handleJobTypeChange} style={{padding: '5px'}}>
                                <option value="Potential Customers">Date Range for Potential Customers</option>
                                <option value="Less than 3 Days"> Less than 3 Days</option>
                                <option value="3 Days">3 Days</option>
                                <option value="More than 3, Less than 7 Days">More than 3, Less than 7 Days</option>
                                <option value="7 Days">7 Days</option>
                                <option value="More than 7, Less than 10 Days"> More than 7, Less than 10 Days</option>
                                <option value="10 Days">10 Days</option>
                                <option value="More than 10, Less than 30 Days"> More than 10, Less than 30 Days</option>
                                <option value="30 Days">30 Days</option>
                                <option value="More than 30, Less than 90 Days"> More than 30, Less than 90 Days</option>
                                <option value="90 Days">90 Days</option>
                                <option value="More than 90 Days"> More than 90 Days</option>
                            </select>
                        </div>

                        <div style={{ marginTop: '20px', border: '1px solid #ccc', padding: '20px', borderRadius: '5px' }}>
                            <label style={{fontWeight: 'bold', marginBottom: '10px', marginRight: '10px'}}>Email Templates for {jobType} - {dateRange} </label>

                            <input type="text" value={subject} onChange={this.handleSubjectChange} style={{padding: '5px', marginBottom: '10px'}} placeholder="Subject" />
                            <Tooltip title="Use {~FirstName~} {~LastName~} as Placeholder to replace placeholder with user's firstname and lastname">
                                <IconButton>
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip>
                            <ReactQuill value={message} onChange={this.handleMessageChange} modules={this.modules} formats={this.formats} />

                            <div style={{textAlign: 'right'}}>
                                <Button color="primary"  style={{padding: '10px', borderRadius: '5px', cursor: 'pointer', marginLeft: '10px', transition: 'transform 0.2s',}} variant="contained" onClick={this.handleAdd}>
                                    ADD
                                </Button>
                                <Button color="primary" style={{padding: '10px', borderRadius: '5px', cursor: 'pointer', marginLeft: '10px', transition: 'transform 0.2s',}} variant="contained" onClick={this.handleUpdate}>
                                    UPDATE
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

}
export default EmailTemplate;



