import React, { useCallback, useEffect, useState } from "react";
import { getAllFormData } from "../../api/invoice-api";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import "@inovua/reactdatagrid-community/base.css";
import Tooltip from "@mui/material/Tooltip";
import "@inovua/reactdatagrid-community/theme/green-light.css";
// import DeleteIcon from '@mui/icons-material/DeleteIcon';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { CSVLink } from "react-csv";
import { Button } from "@mui/material";
import parsePhoneNumber from "libphonenumber-js";

const gridStyle = { minHeight: 570, marginTop: 10 };

const filterValue = [
  { name: "id", operator: "gte", type: "number", value: 0 },
  { name: "email", operator: "startsWith", type: "string", value: "" },
  { name: "firstname", operator: "startsWith", type: "string", value: "" },
  { name: "lastname", operator: "startsWith", type: "string", value: "" },
  { name: "address", operator: "contains", type: "string", value: "" },
  { name: "zipcode", operator: "contains", type: "string", value: "" },
  { name: "city", operator: "startsWith", type: "string", value: "" },

  { name: "phone", operator: "contains", type: "string", value: "" },
  { name: "source", operator: "contains", type: "string", value: "" },
  { name: "message", operator: "contains", type: "string", value: "" },
  { name: "timestamp", operator: "contains", type: "string", value: "" },
  { name: "voucher", operator: "contains", type: "string", value: "" },
];

export const ContactForms = ({ token }) => {
  const [rows, setRows] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [selected, setSelected] = useState({});
  const onSelectionChange = useCallback(({ selected }) => {
    setSelected(selected);
    console.log(selected);
  }, []);

  const getFormData = () => {
    getAllFormData(token).then((response) => {
      const data = response.data;
      // console.log(data);
      for (var index = 0; index < data.length; index++) {
        data[index]["timestamp"] =
          data[index]["timestamp"].slice(0, 10) +
          "\n" +
          data[index]["timestamp"].slice(11, 19);
        data[index]["phone"] = parsePhoneNumber(
          data[index]["phone"],
          "US"
        ).formatNational();
      }
      setRows(data);
      setTotalData(data);
    });
  };
  useEffect(() => {
    // API Calls
    getFormData();
  }, []);

  var columns = [
    {
      name: "id",
      header: "ID",
      visible: true,
      enableColumnFilterContextMenu: false,
      defaultWidth: 50,
      type: "number",
    },
    {
      name: "firstname",
      header: "First Name",
      visible: true,
      enableColumnFilterContextMenu: true,
    },
    {
      name: "lastname",
      header: "Last Name",
      visible: true,
      enableColumnFilterContextMenu: true,
    },
    {
      name: "address",
      header: "Address",
      visible: true,
      enableColumnFilterContextMenu: true,
    },
    {
      name: "zipcode",
      header: "Zipcode",
      visible: true,
      enableColumnFilterContextMenu: true,
    },
    {
      name: "city",
      header: "City",
      visible: true,
      enableColumnFilterContextMenu: true,
    },
    {
      name: "phone",
      header: "Phone Number",
      visible: true,
      enableColumnFilterContextMenu: true,
    },
    {
      name: "email",
      header: "Email",
      visible: true,
      enableColumnFilterContextMenu: true,
    },
    {
      name: "source",
      header: "Source",
      visible: true,
      enableColumnFilterContextMenu: true,
    },
    {
      name: "message",
      header: "Message",
      visible: true,
      render: ({ value }) => {
        return (
          <Tooltip title={value} enterTouchDelay={0}>
            <Box>{value}</Box>
          </Tooltip>
        );
      },
    },
    {
      name: "voucher",
      header: "Voucher",
      visible: true,
    },
    {
      name: "timestamp",
      header: "Time",
      render: ({ value }) => {
        return (
          <Tooltip title={value} enterTouchDelay={0}>
            <Box>{value}</Box>
          </Tooltip>
        );
      },
      visible: true,
    },
  ];
  columns = columns.filter((col) => col.visible === true);

  return (
    <Box>
      <Typography variant="h4" sx={{ flexGrow: 1, p: 1 }}>
        Contact Form
      </Typography>
      <Divider />
      <Box>
        {rows ? (
          <ReactDataGrid
            style={gridStyle}
            columns={columns}
            dataSource={rows}
            pagination
            theme="green-light"
            selected={selected}
            onSelectionChange={onSelectionChange}
            defaultLimit={10}
            defaultFilterValue={filterValue}
            defaultSortInfo={{ name: "id", dir: -1 }}
            // checkboxColumn
            pageSizes={[10, 25, 50]}
            enableColumnFilterContextMenu={true}
          />
        ) : (
          ""
        )}
      </Box>
      <CSVLink filename={"form_submissions.csv"} data={rows} separator={","}>
        <Button color="primary" sx={{ mt: 1 }} variant="contained">
          Download Form Submissions
        </Button>
      </CSVLink>
    </Box>
  );
};
