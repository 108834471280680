import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import NoLoginApp from './NoLoginApp';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AddDetailsForm } from './pages/AddDetailsForm/AddDetailsForm';
import { LoginApp } from './LoginApp';
import Error from './components/Error';
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy';

ReactDOM.render(
  (process.env.REACT_APP_ENV === 'staging') ? (
    <NoLoginApp />
  ) : (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/form" element={<AddDetailsForm />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/" element={<LoginApp />} />
          <Route exact path="/404" element={< Error />} />

        </Routes>
      </BrowserRouter>

    </>
  )

  ,
  document.getElementById("root")
);

// ReactDOM.render(
//   <App>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
