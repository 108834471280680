import './App.css';
import { MiniDrawer } from './components/Drawer';
import { InvoicePage } from './pages/InvoicePage/InvoicePage';
import { QuickbooksPage } from './pages/QuickbooksPage/QuickbooksPage';
import { JobsPage } from './pages/JobsPage/JobsPage';

import React from 'react';
import { LoginButton } from './pages/login';
import { useAuth0 } from '@auth0/auth0-react';
import { ContactForms } from './pages/ContactForm/ContactForm';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Error from './components/Error';
import { EmailBuilder } from './pages/EmailScheduling/EmailBuilder';
import { EmailTabs } from './pages/EmailScheduling/EmailTabs';
import { Map } from './pages/Map';


function App() {
  const [componentIndex, useComponentIndex] = React.useState(0);
  const components = [InvoicePage, QuickbooksPage, ContactForms, JobsPage, /* EmailScheduling, */ Map, EmailTabs];
  const { isAuthenticated } = useAuth0();

  return (
    <>
      {isAuthenticated && <MiniDrawer isAuthenticated={isAuthenticated}
        MainContentComponent={components[componentIndex]}
        usecomponentIndex={useComponentIndex} />}
      {!isAuthenticated && <LoginButton />}
    </>
  )
}

export default App;
