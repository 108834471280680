import Grid from '@mui/material/Grid';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import { Row, Col, Table } from "reactstrap";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Logo from '../../assets/logo.png'
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Divider, TableHead, TableCell, TableRow } from "@mui/material";

// import '../../style/privacy-policy.css'
export const PrivacyPolicy = ({ token }) => {

    const style = {
        textDecoration: "none"
    }
    return (
        <Box component="main" sx={{ flexGrow: 2, pt: 2, height: "100%", background: '#4CAF50' }}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center">
                <Card sx={{ width: "90%", background: '#F9F9F9', color: '#666666', fontFamily: 'Open Sans, Helvetica, Arial, sans-serif' }} variant="outlined" >

                    <CardContent sx={{ m: 5 }}>
                        <a style={{ textDecoration: "none" }} href="https://www.lifetreewny.com/">
                            <Grid container justifyContent="center">
                                <CardMedia
                                    component="img"
                                    image={Logo}
                                    alt="Life Tree LLC"
                                    sx={{
                                        height: "10%", width: "30%"
                                    }}
                                >
                                </CardMedia>
                            </Grid>
                        </a>
                        <Typography variant="h3" align="center" sx={{ flexGrow: 1, p: 1 }}>
                            Life Tree LLC Privacy Policy
                        </Typography>
                        <Typography sx={{ textAlign: 'center' }}>
                            Effective from July 8, 2022
                        </Typography>
                        <br />
                        <Col sm="12" md={{ size: 12 }} >
                            <Typography variant="h4" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                Welcome to Life Tree LLC Privacy Policy
                            </Typography>
                            <br />
                            <Row>
                                <Box sx={{ flexGrow: 1, p: 2 }}>
                                    <Col sm="10" md="10">
                                        This privacy notice for Life Tree Services LLC (doing business as Life Tree Services, LifeTree Services)
                                        ("Life Tree Services, LifeTree Services," "we," "us," or "our"), describes how and why we might collect,
                                        store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
                                    </Col>
                                    <Typography>
                                        <ul>
                                            <li>Visit our website at <a style={{ textDecoration: "none" }} href="https://www.lifetreewny.com/" target="_blank" rel="noopener noreferrer">https://www.lifetreewny.com/</a>, or any website of ours that links to this privacy notice</li>
                                            <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                                        </ul>
                                    </Typography>
                                    <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        Questions or concerns?
                                    </Typography>
                                    <Typography>
                                        Reading this privacy notice will help you understand your privacy rights and choices.
                                        If you do not agree with our policies and practices, please do not use our Services.
                                        If you still have any questions or concerns, please contact us at slifetreewny@gmail.com.
                                    </Typography>
                                    <br />
                                    <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        SUMMARY OF KEY POINTS
                                    </Typography>
                                    <Typography>
                                        This summary provides key points from our privacy notice,
                                        but you can find out more details about any of these topics by clicking the link following each key point or
                                        by using our table of contents below to find the section you are looking for.
                                        You can also click <a style={{ textDecoration: "none" }} href="#toc">here</a> to go directly to our table of contents.
                                    </Typography>
                                    <br />

                                    <Typography variant="h7" sx={{ textAlign: 'left', fontWeight: 'bold', pt: 1 }}>
                                        What personal information do we process?
                                    </Typography>
                                    <Typography>
                                        When you visit, use, or navigate our Services, we may process personal information depending
                                        on how you interact with Life Tree Services, LifeTree Services and the Services, the choices you make,
                                        and the products and features you use. Click <a style={{ textDecoration: "none" }} href="#personalinfo">here</a> to learn more.
                                    </Typography>
                                    <br />
                                    <Typography variant="h7" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        Do we process any sensitive personal information?
                                    </Typography>
                                    <Typography>
                                        We do not process sensitive personal information.
                                    </Typography>
                                    <br />
                                    <Typography variant="h7" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        Do we receive any information from third parties?
                                    </Typography>
                                    <Typography>
                                        We do not receive any information from third parties.
                                    </Typography>
                                    <br />
                                    <Typography variant="h7" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        How do we process your information?
                                    </Typography>
                                    <Typography>
                                        We process your information to provide, improve, and administer our Services,
                                        communicate with you, for security and fraud prevention, and to comply with law.
                                        We may also process your information for other purposes with your consent.
                                        We process your information only when we have a valid legal reason to do so.
                                        Click <a style={{ textDecoration: "none" }} href="#infouse">here</a> to learn more.
                                    </Typography>
                                    <br />
                                    <Typography variant="h7" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        In what situations and with which parties do we share personal information?
                                    </Typography>
                                    <Typography>
                                        We may share information in specific situations and with specific third parties.
                                        Click <a style={{ textDecoration: "none" }} href="#whoshare">here</a> to learn more.
                                    </Typography>
                                    <br />
                                    <Typography variant="h7" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        What are your rights?
                                    </Typography>
                                    <Typography>
                                        Depending on where you are located geographically,
                                        the applicable privacy law may mean you have certain rights regarding your personal information.
                                        Click <a style={{ textDecoration: "none" }} href="#privacyrights">here</a> to learn more.
                                    </Typography>
                                    <br />
                                    <Typography variant="h7" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        How do you exercise your rights?
                                    </Typography>
                                    <Typography>
                                        The easiest way to exercise your rights is by filling out our data subject request form
                                        available <a style={{ textDecoration: "none" }} href="https://app.termly.io/notify/20f533c9-e80c-4404-82d7-922f8706134d" target="_blank" rel="noopener noreferrer">here</a>, or by contacting us.
                                        We will consider and act upon any request in accordance with applicable data protection laws.
                                    </Typography>

                                    <br />
                                    <Typography>
                                        Want to learn more about what Life Tree Services, LifeTree Services does with any information we collect?
                                        Click <a style={{ textDecoration: "none" }} href="#toc">here</a> to review the notice in full.
                                    </Typography>

                                </Box>
                                <Box sx={{ flexGrow: 1, p: 2 }}>
                                    <Typography variant="h6" id="toc" align="left" sx={{ flexGrow: 1, p: 1, pt: 2, fontWeight: 'bold' }}>
                                        TABLE OF CONTENTS
                                    </Typography>
                                    <ol>
                                        <a style={{ textDecoration: "none" }} href="#infocollect"><li>WHAT INFORMATION DO WE COLLECT?</li></a>
                                        <a style={{ textDecoration: "none" }} href="#infouse"><li>HOW DO WE PROCESS YOUR INFORMATION?</li></a>
                                        <a style={{ textDecoration: "none" }} href="#whoshare"><li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li></a>
                                        <a style={{ textDecoration: "none" }} href="#cookies"><li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li></a>
                                        <a style={{ textDecoration: "none" }} href="#inforetain"><li>HOW LONG DO WE KEEP YOUR INFORMATION?</li></a>
                                        <a style={{ textDecoration: "none" }} href="#infominors"><li>DO WE COLLECT INFORMATION FROM MINORS?</li></a>
                                        <a style={{ textDecoration: "none" }} href="#privacyrights"><li>WHAT ARE YOUR PRIVACY RIGHTS?</li></a>
                                        <a style={{ textDecoration: "none" }} href="#DNT"><li>CONTROLS FOR DO-NOT-TRACK FEATURES</li></a>
                                        <a style={{ textDecoration: "none" }} href="#caresidents"><li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li></a>
                                        <a style={{ textDecoration: "none" }} href="#policyupdates"><li>DO WE MAKE UPDATES TO THIS NOTICE?</li></a>
                                        <a style={{ textDecoration: "none" }} href="#contact"><li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li></a>
                                        <a style={{ textDecoration: "none" }} href="#request"><li>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li></a>
                                    </ol>
                                </Box>
                            </Row>
                            <Row>
                                <Typography variant="h6" id="infocollect" align="left" sx={{ flexGrow: 1, p: 1, fontWeight: 'bold' }}>
                                    1. WHAT INFORMATION DO WE COLLECT?
                                </Typography>
                                <Divider />

                                <Row>
                                    <Typography sx={{ pt: 3 }}>
                                        Personal information you disclose to us
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        <strong>In Short: </strong>We collect personal information that you provide to us.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
                                    </Typography>
                                </Row>
                                <Row>
                                    <Typography sx={{ pt: 1 }}>
                                        <strong>Personal Information Provided by You.</strong>
                                        The personal information that we collect depends on the context of your interactions with us
                                        and the Services, the choices you make, and the products and features you use.
                                        The personal information we collect may include the following:
                                    </Typography>
                                    <Typography>
                                        <ul>
                                            <li>names</li>
                                            <li>phone numbers</li>
                                            <li>email addresses</li>
                                            <li>mailing addresses</li>
                                            <li>contact preferences</li>
                                            <li>contact or authentication data</li>
                                            <li>billing addresses</li>
                                        </ul>
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        <strong>Sensitive Information. </strong>
                                        We do not process sensitive information.
                                        All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                                    </Typography>
                                </Row>
                            </Row>
                            <Row>
                                <Typography variant="h6" id="infouse" align="left" sx={{ flexGrow: 1, p: 1, pt: 2, fontWeight: 'bold' }}>
                                    2. HOW DO WE PROCESS YOUR INFORMATION?
                                </Typography>
                                <Divider />
                                <Row>
                                    <Typography sx={{ pt: 3 }}>
                                        <strong>In Short: </strong>We collect personal information that you provide to us.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        We process your information to provide,
                                        improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law.
                                        We may also process your information for other purposes with your consent.
                                    </Typography>
                                    <Typography sx={{ pt: 2, fontWeight: 'bold' }}>
                                        We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        <ul>
                                            <li sx={{ mt: 1 }}>
                                                <strong>To deliver and facilitate delivery of services to the user.</strong>
                                                We may process your information to provide you with the requested service.
                                            </li>
                                            <li>
                                                <strong>To send administrative information to you.</strong>
                                                We may process your information to send you details about our products and services,
                                                changes to our terms and policies, and other similar information.</li>
                                            <li>
                                                <strong>To enable user-to-user communications.</strong>
                                                We may process your information if you choose to use any of our offerings that allow
                                                for communication with another user.
                                            </li>
                                            <li>
                                                <strong>To send you marketing and promotional communications.</strong>
                                                We may process the personal information you send to us for our marketing purposes,
                                                if this is in accordance with your marketing preferences.
                                                <br />You can opt out of our marketing emails at any time.
                                                For more information, see <a style={{ textDecoration: "none" }} href="#privacyrights">"WHAT ARE YOUR PRIVACY RIGHTS?"</a> below.
                                            </li>
                                            <li>
                                                <strong>To deliver targeted advertising to you.</strong>
                                                We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more.
                                            </li>
                                            <li>
                                                <strong>To evaluate and improve our Services, products, marketing, and your experience.</strong>
                                                We may process your information when we believe it is necessary to identify usage trends,
                                                determine the effectiveness of our promotional campaigns, and to evaluate
                                                and improve our Services, products, marketing, and your experience.
                                            </li>
                                            <li>
                                                <strong>To determine the effectiveness of our marketing and promotional campaigns.</strong>
                                                We may process your information to better understand how to provide marketing and promotional
                                                campaigns that are most relevant to you.
                                            </li>
                                            <li>
                                                <strong>To comply with our legal obligations.</strong>
                                                We may process your information to comply with our legal obligations,
                                                respond to legal requests, and exercise, establish, or defend our legal rights.
                                            </li>
                                        </ul>
                                    </Typography>
                                </Row>
                            </Row>
                            <Row>
                                <Typography variant="h6" id="whoshare" align="left" sx={{ flexGrow: 1, p: 1, pt: 2, fontWeight: 'bold' }}>
                                    3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                                </Typography>
                                <Divider />
                                <Row>
                                    <Typography sx={{ pt: 3 }}>
                                        <strong>In Short: </strong>We may share information in specific situations described in this section and/or with the following third parties.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        We may need to share your personal information in the following situations:
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        <ul>
                                            <li>
                                                <strong>Business Transfers.</strong>
                                                We may share or transfer your information in connection with,
                                                or during negotiations of, any merger, sale of company assets, financing, or acquisition of all
                                                or a portion of our business to another company.
                                            </li>
                                            <li>
                                                <strong>When we use Google Maps Platform APIs.</strong>
                                                We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API).
                                                To find out more about Google’s Privacy Policy, please refer to this <a style={{ textDecoration: "none" }} href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">link</a>.
                                                We obtain and store on your device ("cache") your location.
                                                You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.
                                            </li>
                                        </ul>
                                    </Typography>
                                </Row>
                            </Row>
                            <Row>
                                <Typography variant="h6" id="cookies" align="left" sx={{ flexGrow: 1, p: 1, pt: 2, fontWeight: 'bold' }}>
                                    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                                </Typography>
                                <Divider />
                                <Row>
                                    <Typography sx={{ pt: 3 }}>
                                        <strong>In Short: </strong>We may use cookies and other tracking technologies to collect and store your information.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        We may use cookies and similar tracking technologies (like web beacons and pixels)
                                        to access or store information.
                                        Specific information about how we use such technologies
                                        and how you can refuse certain cookies is set out in our Cookie Notice.
                                    </Typography>
                                </Row>
                            </Row>
                            <Row>
                                <Typography variant="h6" id="inforetain" align="left" sx={{ flexGrow: 1, p: 1, pt: 2, fontWeight: 'bold' }}>
                                    5. HOW LONG DO WE KEEP YOUR INFORMATION?
                                </Typography>
                                <Divider />
                                <Row>
                                    <Typography sx={{ pt: 3 }}>
                                        <strong>In Short: </strong>We keep your information for as long as necessary
                                        to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        We may use cookies and similar tracking technologies (like web beacons and pixels)
                                        to access or store information.
                                        Specific information about how we use such technologies
                                        and how you can refuse certain cookies is set out in our Cookie Notice.
                                    </Typography>
                                    <Typography sx={{ pt: 3 }}>
                                        We will only keep your personal information for as long as it is necessary for the purposes
                                        set out in this privacy notice, unless a longer retention period is required or permitted by law
                                        (such as tax, accounting, or other legal requirements).
                                        No purpose in this notice will require us keeping your personal information for longer than 2 years.
                                    </Typography>
                                    <Typography sx={{ pt: 3 }}>

                                        When we have no ongoing legitimate business need to process your personal information,
                                        we will either delete or anonymize such information, or,
                                        if this is not possible (for example, because your personal information has been stored in backup archives),
                                        then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                                    </Typography>
                                </Row>
                            </Row>
                            <Row>
                                <Typography variant="h6" id="infominors" align="left" sx={{ flexGrow: 1, p: 1, pt: 2, fontWeight: 'bold' }}>
                                    6. DO WE COLLECT INFORMATION FROM MINORS?
                                </Typography>
                                <Divider />
                                <Row>
                                    <Typography sx={{ pt: 3 }}>
                                        <strong>In Short: </strong>We do not knowingly collect data from or market to children under 18 years of age.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        We do not knowingly solicit data from or market to children under 18 years of age.
                                        By using the Services, you represent that you are at least 18 or
                                        that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services.
                                        If we learn that personal information from users less than 18 years of age has been collected,
                                        we will deactivate the account and take reasonable measures to promptly delete such data from our records.
                                        If you become aware of any data we may have collected from children under age 18,
                                        please contact us at slifetreewny@gmail.com.
                                    </Typography>
                                </Row>
                            </Row>
                            <Row>
                                <Typography variant="h6" id="privacyrights" align="left" sx={{ flexGrow: 1, p: 1, pt: 2, fontWeight: 'bold' }}>
                                    7. WHAT ARE YOUR PRIVACY RIGHTS?
                                </Typography>
                                <Divider />
                                <Row>
                                    <Typography sx={{ pt: 3 }}>
                                        <strong>In Short: </strong>You may review, change, or terminate your account at any time.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        If you are located in the EEA or UK and you believe we are unlawfully processing your personal information,
                                        you also have the right to complain to your local data protection supervisory authority.
                                        You can find their contact details here:
                                        <a style={{ textDecoration: "none" }} href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" rel="noopener noreferrer" target="_blank">
                                            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                                        </a>
                                    </Typography>
                                    <Typography sx={{ pt: 3 }}>
                                        If you are located in Switzerland, the contact details for the data protection authorities are available here:
                                        <a style={{ textDecoration: "none" }} href="https://www.edoeb.admin.ch/edoeb/en/home.html" rel="noopener noreferrer" target="_blank">
                                            https://www.edoeb.admin.ch/edoeb/en/home.html
                                        </a>
                                    </Typography>
                                    <Typography sx={{ pt: 3 }}>
                                        <strong>Withdrawing your consent: </strong>
                                        If we are relying on your consent to process your personal information,
                                        which may be express and/or implied consent depending on the applicable law,
                                        you have the right to withdraw your consent at any time.
                                        You can withdraw your consent at any time by contacting us
                                        by using the contact details provided in the section <a style={{ textDecoration: "none" }} href="#contact">
                                            "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
                                        </a> below.
                                        You will then be removed from the marketing lists.
                                        However, we may still communicate with you —
                                        for example, to send you service-related messages that are necessary
                                        for the administration and use of your account, to respond to service requests,
                                        or for other non-marketing purposes.
                                    </Typography>

                                    <Typography sx={{ pt: 3 }}>
                                        <strong>Cookies and similar technologies: </strong>
                                        Most Web browsers are set to accept cookies by default.
                                        If you prefer, you can usually choose to set your browser to
                                        remove cookies and to reject cookies.
                                        If you choose to remove cookies or reject cookies,
                                        this could affect certain features or services of our Services.
                                        To opt out of interest-based advertising by advertisers
                                        on our Services visit <a style={{ textDecoration: "none" }} href="http://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer">
                                            http://www.aboutads.info/choices/
                                        </a>.
                                    </Typography>
                                    <Typography sx={{ pt: 3 }}>
                                        If you have questions or comments about your privacy rights,
                                        you may email us at slifetreewny@gmail.com.
                                    </Typography>
                                </Row>
                            </Row>
                            <Row>
                                <Typography variant="h6" id="DNT" align="left" sx={{ flexGrow: 1, p: 1, pt: 2, fontWeight: 'bold' }}>
                                    8. CONTROLS FOR DO-NOT-TRACK FEATURES
                                </Typography>
                                <Divider />
                                <Row>
                                    <Typography sx={{ pt: 3 }}>
                                        Most web browsers and some mobile operating systems and mobile applications
                                        include a Do-Not-Track ("DNT") feature or setting you can activate
                                        to signal your privacy preference not to have data about your online browsing
                                        activities monitored and collected.
                                        At this stage no uniform technology standard for recognizing and implementing
                                        DNT signals has been finalized.
                                        As such, we do not currently respond to DNT browser signals or any other mechanism
                                        that automatically communicates your choice not to be tracked online.
                                        If a standard for online tracking is adopted that we must follow in the future,
                                        we will inform you about that practice in a revised version of this privacy notice.
                                    </Typography>
                                </Row>
                            </Row>
                            <Row>
                                <Typography variant="h6" id="caresidents" align="left" sx={{ flexGrow: 1, p: 1, pt: 2, fontWeight: 'bold' }}>
                                    9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                                </Typography>
                                <Divider />
                                <Row>
                                    <Typography sx={{ pt: 3 }}>
                                        <strong>In Short: </strong> Yes, if you are a resident of California,
                                        you are granted specific rights regarding access to your personal information.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        California Civil Code Section 1798.83, also known as the "Shine The Light" law,
                                        permits our users who are California residents to request and obtain from us,
                                        once a year and free of charge, information about categories of personal information
                                        (if any) we disclosed to third parties for direct marketing purposes and
                                        the names and addresses of all third parties with which we shared personal information
                                        in the immediately preceding calendar year.
                                        If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                                    </Typography>
                                    <Typography sx={{ pt: 3 }}>
                                        If you are under 18 years of age, reside in California,
                                        and have a registered account with Services,
                                        you have the right to request removal of unwanted data
                                        that you publicly post on the Services.
                                        To request removal of such data, please contact us using the contact information
                                        provided below and include the email address associated with your account
                                        and a statement that you reside in California.
                                        We will make sure the data is not publicly displayed on the Services,
                                        but please be aware that the data may not be completely
                                        or comprehensively removed from all our systems (e.g., backups, etc.).
                                    </Typography>
                                    <Typography variant="h7" align="left" sx={{ flexGrow: 1, pt: 2, fontWeight: 'bold' }}>
                                        CCPA Privacy Notice
                                    </Typography>
                                    <Typography sx={{ pt: 3 }}>
                                        The California Code of Regulations defines a "resident" as:
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        <ol>
                                            <li>every individual who is in the State of California for other than a temporary or transitory purpose and</li>
                                            <li>every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose.</li>
                                        </ol>
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        All other individuals are defined as "non-residents.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        "If this definition of "resident" applies to you,
                                        we must adhere to certain rights and obligations regarding your personal information.
                                    </Typography>
                                    <Typography sx={{ pt: 3 }}>
                                        <strong>What categories of personal information do we collect?</strong>
                                    </Typography>
                                    <Typography sx={{ pt: 3 }}>
                                        We have collected the following categories of personal information in the past twelve (12) months:
                                    </Typography>
                                    <Table>
                                        <TableHead>
                                            <TableCell>Category</TableCell>
                                            <TableCell>Examples</TableCell>
                                            <TableCell>Collected</TableCell>
                                        </TableHead>
                                        <TableRow>
                                            <TableCell>A. Identifiers</TableCell>
                                            <TableCell>
                                                Contact details, such as real name, alias, postal address,
                                                telephone or mobile contact number,
                                                unique personal identifier, online identifier, Internet Protocol address,
                                                email address, and account name
                                            </TableCell>
                                            <TableCell>YES</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>B.
                                                Personal information categories listed in the California
                                                Customer Records statute
                                            </TableCell>
                                            <TableCell>
                                                Contact details, such as real name, alias, postal address,
                                                telephone or mobile contact number,
                                                unique personal identifier, online identifier, Internet Protocol address,
                                                email address, and account name
                                            </TableCell>
                                            <TableCell>YES</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>C.
                                                Protected classification characteristics
                                                under California or federal law
                                            </TableCell>
                                            <TableCell>
                                                Gender and date of birth
                                            </TableCell>
                                            <TableCell>YES</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>D.
                                                Commercial information
                                            </TableCell>
                                            <TableCell>
                                                Transaction information, purchase history, financial details, and payment information
                                            </TableCell>
                                            <TableCell>NO</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>E.
                                                Biometric information
                                            </TableCell>
                                            <TableCell>
                                                Fingerprints and voiceprints
                                            </TableCell>
                                            <TableCell>NO</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>F.
                                                Internet or other similar network activity
                                            </TableCell>
                                            <TableCell>
                                                Browsing history, search history,
                                                online behavior, interest data, and interactions with our and other websites,
                                                applications, systems, and advertisements
                                            </TableCell>
                                            <TableCell>NO</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>G.
                                                Geolocation data
                                            </TableCell>
                                            <TableCell>
                                                Device location
                                            </TableCell>
                                            <TableCell>NO</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>H.
                                                Audio, electronic, visual, thermal, olfactory, or similar information
                                            </TableCell>
                                            <TableCell>
                                                Images and audio, video or call recordings
                                                created in connection with our business activities
                                            </TableCell>
                                            <TableCell>NO</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>I.
                                                Audio, electronic, visual, thermal, olfactory, or similar information
                                            </TableCell>
                                            <TableCell>
                                                Images and audio, video or call recordings
                                                created in connection with our business activities
                                            </TableCell>
                                            <TableCell>NO</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>I.
                                                Professional or employment-related information
                                            </TableCell>
                                            <TableCell>
                                                Business contact details in order to provide you our services at a business level
                                                or job title, work history, and professional qualifications
                                                if you apply for a job with us
                                            </TableCell>
                                            <TableCell>NO</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>J.
                                                Education Information
                                            </TableCell>
                                            <TableCell>
                                                Student records and directory information
                                            </TableCell>
                                            <TableCell>NO</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>K.
                                                Inferences drawn from other personal information
                                            </TableCell>
                                            <TableCell>
                                                Inferences drawn from any of the collected personal information
                                                listed above to create a profile or summary about,
                                                for example, an individual’s preferences and characteristics
                                            </TableCell>
                                            <TableCell>NO</TableCell>
                                        </TableRow>
                                    </Table>
                                    <Typography sx={{ pt: 1 }}>
                                        We may also collect other personal information outside of these categories
                                        instances where you interact with us in person, online, or by phone or mail in the context of:
                                        <ul>
                                            <li>Receiving help through our customer support channels;</li>
                                            <li>Participation in customer surveys or contests; and</li>
                                            <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                                        </ul>
                                    </Typography>
                                    <Typography sx={{ pt: 3, fontWeight: 'bold' }}>
                                        How do we use and share your personal information?
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        More information about our data collection and sharing practices can be found in this privacy notice.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        You may contact us by email at slifetreewny@gmailcom, or by referring to the contact details at the bottom of this document.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        If you are using an authorized agent to exercise your right to opt out we may deny a request
                                        if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.
                                    </Typography>
                                    <Typography sx={{ pt: 3, fontWeight: 'bold' }}>
                                        Will your information be shared with anyone else?
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider.
                                        Each service provider is a for-profit entity that processes the information on our behalf.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration.
                                        This is not considered to be "selling" of your personal information.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        Life Tree Services LLC has not disclosed or sold any personal information to third parties
                                        for a business or commercial purpose in the preceding twelve (12) months.
                                        Life Tree Services LLC will not sell personal information in the future belonging to website
                                        visitors, users, and other consumers.
                                    </Typography>
                                    <Typography sx={{ pt: 3, fontWeight: 'bold' }}>
                                        Your rights with respect to your personal data
                                    </Typography>
                                    <Typography sx={{ pt: 1, textDecoration: 'underline' }}>
                                        Right to request deletion of the data — Request to delete
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        You can ask for the deletion of your personal information.
                                        If you ask us to delete your personal information, we will respect your request
                                        and delete your personal information, subject to certain exceptions provided by law,
                                        such as (but not limited to) the exercise by another consumer of his or her right
                                        to free speech, our compliance requirements resulting from a legal obligation,
                                        or any processing that may be required to protect against illegal activities.
                                    </Typography>
                                    <Typography sx={{ pt: 1, textDecoration: 'underline' }}>
                                        Right to be informed — Request to know
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        Depending on the circumstances, you have a right to know:
                                        <ul>
                                            <li>whether we collect and use your personal information;</li>
                                            <li>the categories of personal information that we collect;</li>
                                            <li>the purposes for which the collected personal information is used;</li>
                                            <li>whether we sell your personal information to third parties;</li>
                                            <li>the categories of personal information that we sold or disclosed for a business purpose;</li>
                                            <li>the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and</li>
                                            <li>the business or commercial purpose for collecting or selling personal information.</li>
                                        </ul>
                                        In accordance with applicable law, we are not obligated to provide or delete consumer
                                        information that is de-identified in response to a consumer request or to re-identify
                                        individual data to verify a consumer request.
                                    </Typography>
                                    <Typography sx={{ pt: 1, textDecoration: 'underline' }}>
                                        Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        We will not discriminate against you if you exercise your privacy rights.
                                    </Typography>
                                    <Typography sx={{ pt: 1, textDecoration: 'underline' }}>
                                        Verification process
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        Upon receiving your request, we will need to verify your identity
                                        to determine you are the same person about whom we have the information in our system.
                                        These verification efforts require us to ask you to provide information
                                        so that we can match it with information you have previously provided us.
                                        For instance, depending on the type of request you submit,
                                        we may ask you to provide certain information so that we can match
                                        the information you provide with the information we already have on file,
                                        or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us.
                                        We may also use other verification methods as the circumstances dictate.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        We will only use personal information provided in
                                        your request to verify your identity or authority to make the request.
                                        To the extent possible, we will avoid requesting additional
                                        information from you for the purposes of verification.
                                        However, if we cannot verify your identity from the information already
                                        maintained by us, we may request that you provide additional information
                                        for the purposes of verifying your identity and for security
                                        or fraud-prevention purposes.
                                        We will delete such additionally provided information as soon as we
                                        finish verifying you
                                    </Typography>
                                    <Typography sx={{ pt: 1, textDecoration: 'underline' }}>
                                        Other privacy rights
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        <ul>
                                            <li>
                                                You may object to the processing of your personal information.
                                            </li>
                                            <li>
                                                You may request correction of your personal data
                                                if it is incorrect or no longer relevant, or ask to restrict the processing of the information.
                                            </li>
                                            <li>
                                                You can designate an authorized agent to make a request under the CCPA on your behalf.
                                                We may deny a request from an authorized agent that does not submit
                                                proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
                                            </li>
                                            <li>
                                                You may request to opt out from future selling of your personal information to third parties.
                                                Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible,
                                                but no later than fifteen (15) days from the date of the request submission.
                                            </li>
                                        </ul>
                                        To exercise these rights, you can contact us
                                        by email at slifetreewny@gmailcom, or by referring to the contact details
                                        at the bottom of this document.
                                        If you have a complaint about how we handle your data, we would like to hear from you.
                                    </Typography>
                                </Row>
                            </Row>
                            <Row>
                                <Typography variant="h6" id="policyupdates" align="left" sx={{ flexGrow: 1, p: 1, pt: 2, fontWeight: 'bold' }}>
                                    10. DO WE MAKE UPDATES TO THIS NOTICE?
                                </Typography>
                                <Divider />
                                <Row>
                                    <Typography sx={{ pt: 3 }}>
                                        <strong>In Short: </strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        We may update this privacy notice from time to time.
                                        The updated version will be indicated by an updated "Revised" date
                                        and the updated version will be effective as soon as it is accessible.
                                        If we make material changes to this privacy notice,
                                        we may notify you either by prominently posting a notice of such changes
                                        or by directly sending you a notification.
                                        We encourage you to review this privacy notice frequently to be informed
                                        of how we are protecting your information.
                                    </Typography>
                                </Row>
                            </Row>
                            <Row>
                                <Typography variant="h6" id="contact" align="left" sx={{ flexGrow: 1, p: 1, pt: 2, fontWeight: 'bold' }}>
                                    11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                                </Typography>
                                <Divider />
                                <Row>
                                    <Typography sx={{ pt: 1 }}>
                                        If you have questions or comments about this notice,
                                        you may email us at slifetreewny@gmail.com or by post to:

                                    </Typography>
                                    <Typography sx={{ pt: 3 }}>
                                        Life Tree Services LLC
                                        <br />
                                        2660 Millersport Hwy
                                        <br />
                                        Getzville, NY 14068
                                        <br />
                                        United States

                                    </Typography>
                                </Row>
                            </Row>
                            <Row>
                                <Typography variant="h6" id="request" align="left" sx={{ flexGrow: 1, p: 1, pt: 2, fontWeight: 'bold' }}>
                                    12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                                </Typography>
                                <Divider />
                                <Row>
                                    <Typography sx={{ pt: 1 }}>
                                        Based on the applicable laws of your country,
                                        you may have the right to request access to the personal information
                                        we collect from you, change that information, or delete it.
                                        To request to review, update, or delete your personal information,
                                        please submit a request form by clicking <a style={{ textDecoration: "none" }} href="https://app.termly.io/notify/20f533c9-e80c-4404-82d7-922f8706134d" rel="noopener noreferrer" target="_blank">here</a>.
                                    </Typography>
                                    <Typography sx={{ pt: 1 }}>
                                        This privacy policy was created using Termly's <a style={{ textDecoration: "none" }} href="https://termly.io/products/privacy-policy-generator" target="_blank" rel="noopener noreferrer" >
                                            Privacy Policy Generator
                                        </a>.
                                    </Typography>
                                </Row>
                            </Row>

                        </Col>
                        <br />
                    </CardContent>
                </Card>
            </Grid>
        </Box >
    );
};