import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Button } from '@mui/material';
import moment from 'moment';
import { CSVLink, CSVDownload } from "react-csv";
import { getEmailAddresses } from '../../api/invoice-api';


export const DownloadContacts = ({ token }) => {

    const style = {

        underLineText: {
            textDecoration: "none",
            backgroundColor: "#1565c0",
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            padding: "6px 16px",
            color: "white",
            justifyContent: "center",
            position: "relative",
            boxSizing: "border-box",
            lineHeight: "1.75",
            transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            textTransform: "uppercase",
            borderRadius: "4px",
            display: "-webkit-inline-flex",
            webkitAlignItems: "center",
            webkitBoxAlign: "center",
            marginTop: "1%"
        }
    }
    const [allEmailAddresses, setAllEmailAddresses] = useState([]);
    const [quotedEmailAddresses, setQuotedEmailAddresses] = useState([]);
    const [wonEmailAddresses, setWonEmailAddresses] = useState([]);


    const getAllAddresses = () => {
        getEmailAddresses(token, "all").then(response => {
            const data = response.data;
            setAllEmailAddresses(data);
        });
    }

    let currentDate = moment().format("DD-MMM-YYYY")
    const getQuotedAddresses = () => {
        getEmailAddresses(token, "Quoted").then(response => {
            const data = response.data;
            setQuotedEmailAddresses(data);

        });
    }

    const getWonAddresses = () => {
        getEmailAddresses(token, "Won").then(response => {
            const data = response.data;
            setWonEmailAddresses(data);
        });
    }


    useEffect(() => {
        // API Calls
        if (allEmailAddresses.length > 0)
            setAllEmailAddresses([]);
    }, [allEmailAddresses]);

    useEffect(() => {
        // API Calls
        if (quotedEmailAddresses.length > 0)
            setQuotedEmailAddresses([]);
    }, [quotedEmailAddresses]);

    useEffect(() => {
        // API Calls
        if (wonEmailAddresses.length > 0)
            setWonEmailAddresses([]);
    }, [wonEmailAddresses]);

    return (
        <Box sx={{ height: "100%" }}>
            <br />
            <Typography variant="h4" sx={{ flexGrow: 1, p: 1 }}>
                Download Contacts
            </Typography>
            <Divider />

            {
                allEmailAddresses.length > 0 &&
                (<CSVDownload filename={"all_emails" + currentDate} data={allEmailAddresses} separator={","}>
                    Download All email Addresses
                </CSVDownload >)
            }
            {
                wonEmailAddresses.length > 0 &&
                (<CSVDownload filename={"won_emails_" + currentDate} data={wonEmailAddresses} separator={","}>
                    Download Won email Addresses
                </CSVDownload>)
            }
            {
                quotedEmailAddresses.length > 0 &&
                (<CSVDownload filename={"quoted_emails_" + currentDate} data={quotedEmailAddresses} separator={","} >
                    Download Quoted email Addresses
                </CSVDownload >)
            }
            <Button color="primary" sx={{ mt: 1 }} variant="contained" onClick={() => { getAllAddresses() }}>
                Download All email Addresses
            </Button>
            <br />
            <Button color="primary" sx={{ mt: 1 }} variant="contained" onClick={() => { getWonAddresses() }}>
                Download Won email Addresses
            </Button>
            <br />
            <Button color="primary" sx={{ mt: 1 }} variant="contained" onClick={() => { getQuotedAddresses() }}>
                Download Quoted email Addresses
            </Button>
        </Box>
    );
};
