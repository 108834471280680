import React, { useCallback, useEffect, useState } from 'react';
import {  getAllInvoiceDetails, updateProposal } from "../../api/invoice-api";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { green, red } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Checkbox } from '@mui/material';
import { withStyles } from "@material-ui/core/styles";
import { JobsModal } from './JobsModal/JobsModal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomColorCheckbox = withStyles({
    root: {
        color: "#1b867b",
        "&$checked": {
            color: "#1b867b"
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

const exported_icons = [<CloseRoundedIcon sx={{ color: red[500] }} />, <CheckRoundedIcon sx={{ color: green[500] }} />];



const gridStyle = { minHeight: 570, marginTop: 10 }

const filterValue = [
    { name: 'Email', operator: 'startsWith', type: 'string', value: '' },
    { name: 'First Name', operator: 'startsWith', type: 'string', value: '' },
    { name: 'Last Name', operator: 'startsWith', type: 'string', value: '' },
    { name: "Shipping Address Line 1", operator: 'contains', type: 'string', value: '' },
    { name: "Shipping Address Postal Code", operator: 'contains', type: 'string', value: '' },
    { name: "Shipping Address City", operator: 'startsWith', type: 'string', value: '' },
    { name: "Shipping Address State", operator: 'startsWith', type: 'string', value: '' },
    { name: "Phone", operator: 'contains', type: 'string', value: '' },
    { name: "Proposal Status", operator: 'startsWith', type: 'string', value: '' },
    { name: "Proposal Type", operator: 'startsWith', type: 'string', value: '' },
    { name: "Total", operator: 'gte', type: 'number', value: 0 },
    { name: "Date", operator: 'contains', type: 'string', value: '' },
    { name: "Tax Name", operator: 'startsWith', type: 'string', value: '' }

];

export const JobsPage = ({ token }) => {
    const options = ['Open Jobs only', 'Hide Jobs before 60 days'];
    const [snackBarState, setSnackBarState] = React.useState({
        openSnackbar: false,
        vertical: 'top',
        horizontal: 'center',
        message: "Successful !",
        severity: "success"
    });
    const statusList = [
        { 'name': 'Not Started', 'value': 'Not Started' },
        { 'name': 'In Progress', 'value': 'In Progress' },
        { 'name': 'Completed', 'value': 'Completed' },
        { 'name': 'Fix', 'value': 'Fix' },
    ];
    // const statusListCombo=['Not Started', 'Started', 'Pending', 'Completed'];
    const { vertical, horizontal, openSnackbar, message, severity } = snackBarState;
    const [rows, setRows] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [proposalId, setProposalId] = useState("");
    const [loading, setIsLoaded] = useState(false);
    const [checkedState, setCheckedState] = useState([false, true]);

    // Dropdown Change select tag
    const handleStateChange = (event) => {
        setRows([]);
        let proposalId = event.target.name;
        let selectedJobStatus = event.target.value;
        //console.log(`Proposal ID : ${proposalId} || Job Status : ${selectedJobStatus}`);

        let updateObj = {
            'proposal_id': proposalId,
            'job_status': selectedJobStatus
        }

        updateProposal(token, updateObj).then(response => {
            setSnackBarState({ ...snackBarState, openSnackbar: true, message: "Updated Successfully", severity: "success" });
            //console.log(response);
        }).catch((err) => {
            //console.log(err);
            setSnackBarState({ ...snackBarState, openSnackbar: true, message: "There was an error updating value.", severity: "error" });
        });
        let data = [...rows];
        for (let i = 0; i < data.length; i++) {
            if (data[i]["Proposal ID"] === proposalId) {
                data[i]['Job Status'] = selectedJobStatus;
            }
        }

        setRows(data);
    };

    const CheckBox = ({ label, value, onChange }) => {
        return (
            <label>
                <CustomColorCheckbox checked={value} onChange={onChange} />
                {label}
            </label>
        );
    };

    const handleModalOpen = (id) => {
        setProposalId(id);
    };


    const handleSnackbarClose = () => {
        setSnackBarState({ ...snackBarState, openSnackbar: false });
    };


    const getInvoices = () => {
        getAllInvoiceDetails(token).then(response => {
            setTotalData([]);
            setRows([]);
            const totalData = response.data;
            let data = totalData.filter(item => item['Proposal Status'] === 'Won')

            for (let i = 0; i < data.length; i++) {
                data[i]['Shipping Address Line 1'] += " " + data[i]['Shipping Address Line 2'];
                data[i]['Date'] = data[i]['Date'].slice(0, 10);
                if (!data[i]['Amount Paid']) {
                    data[i]['Amount Paid'] = 0.00;
                }
                if (!data[i]['Employee Notes']) {
                    data[i]['Employee Notes'] = "";
                }
                if (!data[i]['Job Status']) {
                    data[i]['Job Status'] = "Not Started";
                }
            }

            //console.log(data);
            setTotalData(data);
            let res = [];
            for (let index = 0; index < data.length; index++) {
                if (calcdate(data[index]['Date']) <= 60) {
                    res.push(data[index])
                }
            }
            setRows(res);
        });
    }

    const defaultSortInfo = { name: 'Date', dir: -1 }
    
    useEffect(() => {
        // API Calls
        getInvoices();
    }, []);

    const onEditComplete = useCallback(({ value, columnId, rowId }) => {
        const data = [...rows];
        let json = []
        for (let index = 0; index < data.length; index++) {
            if (data[index]["Proposal ID"] === rowId) {
                data[index][columnId] = value
                json.push({
                    'proposal_id': data[index]["Proposal ID"],
                    'employee_notes': data[index]["Employee Notes"],
                    'job_status': data[index]["Job Status"],
                    'amount_paid': data[index]["Amount Paid"]
                })
            }
        }
        //console.log("onEditComplete : ", json[0]);
        updateProposal(token, json[0]).then(response => {
            //console.log(response);
            setSnackBarState({ ...snackBarState, openSnackbar: true, message: "Updated Successfully", severity: "success" });
        }).catch((err) => {
            //console.log(err);
            setSnackBarState({ ...snackBarState, openSnackbar: true, message: "There was an error updating value.", severity: "error" });
        });
        setRows(data)
    }, [rows]);

    const columns = [
        {
            name: "First Name",
            header: "First Name",
            visible: true,
            sortable: true
        }, {
            name: "Last Name",
            header: "Last Name",
            visible: true
        },
        {
            name: "Shipping Address Line 1",
            header: "Address",
            visible: true,
            enableColumnFilterContextMenu: true
        },
        {
            name: "Shipping Address Postal Code",
            header: "Zipcode",
            visible: true,
            enableColumnFilterContextMenu: true
        }, {
            name: "Shipping Address City",
            header: "City",
            visible: true,
            enableColumnFilterContextMenu: true
        }, {
            name: "Shipping Address State",
            header: "State",
            visible: true,
            enableColumnFilterContextMenu: true
        },
        {
            name: "Total",
            header: "Total",
            visible: true,
            type: 'number',
            enableColumnFilterContextMenu: true
        },
        {
            name: "Proposal ID",
            header: "View",
            visible: true,
            defaultWidth: 80,
            render: ({ value }) => {
                return <SearchRoundedIcon
                    onClick={() => { handleModalOpen(value); }}
                    sx={{
                        cursor: 'pointer',
                        '&:hover': {
                            color: '#1b867b',
                            borderColor: '#1b867b'
                        },
                    }}
                />
            },
            enableColumnFilterContextMenu: false
        }, {
            name: "Activity ID",
            header: "Activity ID",
            visible: false
        }, {
            name: "QB_EXPORTED",
            header: "Exported",
            visible: false,
            defaultWidth: 100,
            render: ({ value }) => {
                return exported_icons[value] ? exported_icons[value] : 'unknown'
            }
        },
        {
            name: "Job Status",
            header: "Job Status ",
            visible: true,
            defaultWidth: 180,
            render: ({ value, data }) => {
                // //console.log(data)
                let tagColor = "#dc3545";
                if (data['Job Status'] === "Not Started") {
                    tagColor = "white";
                } else if (data['Job Status'] === "In Progress") {
                    tagColor = "#ffc10770";
                } else if (data['Job Status'] === "Completed") {
                    tagColor = "#19875470";
                } else {
                    tagColor = "#0d6efd70";
                }
                return (
                    <Select
                        sx={{ m: 1, minWidth: 140, padding: 0, border: `1px solid black`, backgroundColor: tagColor }} size="small"
                        id="Job Status"
                        // name="Job Status"
                        name={data['Proposal ID']}
                        value={data['Job Status']}
                        onChange={(e) => handleStateChange(e, value)}
                    >
                        {statusList.map((option) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                            >
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                );
            }
        }, {
            name: "Phone",
            header: "Phone Number",
            visible: true,
            enableColumnFilterContextMenu: true
        }, {
            name: "Email",
            header: "Email",
            visible: true,
            enableColumnFilterContextMenu: true
        }, {
            name: "Shipping Address Line 2",
            header: "Shipping Address Line 2",
            visible: false
        }, {
            name: "Billing Address Line 1",
            header: "Billing Address Line 1",
            visible: false
        }, {
            name: "Billing Address Line 2",
            header: "Billing Address Line 2",
            visible: false
        }, {
            name: "Billing Address City",
            header: "Billing Address City",
            visible: false
        }, {
            name: "Billing Address Postal Code",
            header: "Billing Address Postal Code",
            visible: false
        }, {
            name: "Proposal Name",
            header: "Proposal Name",
            visible: false
        }, {
            name: "Proposal Status",
            header: "Status",
            visible: true,
            enableColumnFilterContextMenu: true
        }, {
            name: "Proposal Type",
            header: "Type",
            visible: true,
            enableColumnFilterContextMenu: true
        }, {
            name: "Proposal Source",
            header: "Proposal Source",
            visible: false
        }, {
            name: "Proposal Number",
            header: "Proposal Number",
            visible: false
        }, {
            name: "Date",
            header: "Date",
            visible: true,
            enableColumnFilterContextMenu: true,
            dateFormat: 'YYYY-MM-DD',
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                // for range and notinrange operators, the index is 1 for the after field
                return {
                    dateFormat: 'YYYY-MM-DD',
                    placeholder: index === 1 ? '' : ''
                }
            },
            render: ({ value, cellProps: { dateFormat } }) =>
                moment(value).format(dateFormat),
        }, {
            name: "Time",
            header: "Time",
            visible: false
        }, {
            name: "Subtotal",
            header: "Subtotal",
            type: 'number',
            visible: false
        }, {
            name: "Tax Total",
            header: "Tax Total",
            type: 'number',
            visible: false
        }, {
            name: "Tax Name",
            header: "Tax Name",
            visible: true,
            enableColumnFilterContextMenu: true
        }, {
            name: "Tax Rate",
            header: "Tax Rate",
            visible: false
        }, {
            name: "Number Of Items",
            header: "Number Of Items",
            visible: false
        }, {
            name: "Customer Notes",
            header: "Customer Notes",
            visible: false
        }, {
            name: "Amount Paid",
            header: "Amount Paid",
            visible: true,
            type: 'number',
            editable: true
        }, {
            name: "Employee Notes",
            header: "Employee Notes",
            visible: true,
            editable: true
        },
        {
            name: "completed",
            header: "completed",
            visible: false
        },
        {
            name: "dropbox_url",
            header: "Dropbox",
            visible: true,
            defaultWidth: 100,
            render: ({ value }) => {
                return value ? <a href={value} target="_blank">Link</a> : ""
            }
        }
    ];
    columns.filter(col => col.visible === true);




    function calcdate(a) {
        const curr_date = Date.now();
        var api_date = new Date(a);
        var current = new Date(curr_date);
        return (Math.floor((current - api_date) / (1000 * 60 * 60 * 24)));
    }

    const handleChange = (position) => {
        // //console.log(`handleChange:Position : ${position}`)
        const updatedCheckedState = checkedState.map((item, index) => index === position ? !item : item);
        setCheckedState(updatedCheckedState);
        let res = []
        if (updatedCheckedState[0] && updatedCheckedState[1]) {
            for (let index = 0; index < totalData.length; index++) {
                if (totalData[index]['Proposal Status'] === "Won" && totalData[index]['Job Status'] !== "Completed" && calcdate(totalData[index]['Date']) <= 60) {
                    res.push(totalData[index])
                }
            }
            setRows(res);
        }
        else if (updatedCheckedState[0]) {
            for (let index = 0; index < totalData.length; index++) {
                if (totalData[index]['Proposal Status'] === "Won" && totalData[index]['Job Status'] !== "Completed") {
                    res.push(totalData[index])
                }
            }
            setRows(res);

        }
        else if (updatedCheckedState[1]) {
            //console.log("Hide less than 60 days")
            for (let index = 0; index < totalData.length; index++) {
                if (calcdate(totalData[index]['Date']) <= 60) {
                    res.push(totalData[index])
                }
            }
            setRows(res);
        }
        else {
            setRows(totalData)
        }



    };

    return (
        <Box >
            <Snackbar anchorOrigin={{ vertical, horizontal }}
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                key={vertical + horizontal}>
                <Alert onClose={handleSnackbarClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            // onClick={handleCloseBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Typography variant="h4" sx={{ flexGrow: 1, p: 1 }}>
                Jobs
            </Typography>
            <Divider />
            <Box>
                {options.map((option, index) => {
                    return (
                        <label key={index}>
                            <CheckBox
                                label={option}
                                value={checkedState[index]}
                                onChange={() => handleChange(index)}
                            />
                        </label>
                    );

                }
                )}
            </Box>
            <Box>


            </Box>
            {proposalId !== "" && (<JobsModal token={token} proposalId={proposalId} setProposalId={setProposalId} />)}

            <Box>
                {rows ? <ReactDataGrid
                    idProperty="Proposal ID"
                    style={gridStyle}
                    columns={columns}
                    initialState={{
                        sorting: { sortModel: [{ field: "First Name", sort: "asc" }] }
                    }}
                    dataSource={rows}
                    rowHeight={50}
                    pagination
                    theme='green-light'
                    defaultLimit={10}
                    defaultFilterValue={filterValue}
                    onEditComplete={onEditComplete}
                    defaultSortInfo={defaultSortInfo}
                    pageSizes={[10, 25, 50]}
                    enableColumnFilterContextMenu={true}
                /> : ""}
            </Box>
        </Box>
    )
}
