const minutesList = [
    {
        name: "Every Minute",
        value: "*"
    }, {
        name: "Every Five Minutes",
        value: "*/5"
    }, {
        name: "Every Ten Minutes",
        value: "*/10"
    }, {
        name: "Every Fifteen Minutes",
        value: "*/15"
    },
    { name: "0", value: "0" },
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    { name: "6", value: "6" },
    { name: "7", value: "7" },
    { name: "8", value: "8" },
    { name: "9", value: "9" },
    { name: "10", value: "10" },
    { name: "11", value: "11" },
    { name: "12", value: "12" },
    { name: "13", value: "13" },
    { name: "14", value: "14" },
    { name: "15", value: "15" },
    { name: "16", value: "16" },
    { name: "17", value: "17" },
    { name: "18", value: "18" },
    { name: "19", value: "19" },
    { name: "20", value: "20" },
    { name: "21", value: "21" },
    { name: "22", value: "22" },
    { name: "23", value: "23" },
    { name: "24", value: "24" },
    { name: "25", value: "25" },
    { name: "26", value: "26" },
    { name: "27", value: "27" },
    { name: "28", value: "28" },
    { name: "29", value: "29" },
    { name: "20", value: "30" },
    { name: "31", value: "31" },
    { name: "32", value: "32" },
    { name: "33", value: "33" },
    { name: "34", value: "34" },
    { name: "35", value: "35" },
    { name: "36", value: "36" },
    { name: "37", value: "37" },
    { name: "38", value: "38" },
    { name: "39", value: "39" },
    { name: "40", value: "40" },
    { name: "41", value: "41" },
    { name: "42", value: "42" },
    { name: "43", value: "43" },
    { name: "44", value: "44" },
    { name: "45", value: "45" },
    { name: "46", value: "46" },
    { name: "47", value: "47" },
    { name: "48", value: "48" },
    { name: "49", value: "49" },
    { name: "50", value: "50" },
    { name: "51", value: "51" },
    { name: "52", value: "52" },
    { name: "53", value: "53" },
    { name: "54", value: "54" },
    { name: "55", value: "55" },
    { name: "56", value: "56" },
    { name: "57", value: "57" },
    { name: "58", value: "58" },
    { name: "59", value: "59" }
];

const hoursList = [
    {
        name: "Every Hour",
        value: "*"
    },
    {
        name: "Every Four Hour",
        value: "*/4"
    },
    {
        name: "Every Six Hour",
        value: "*/6"
    },
    {
        name: "0",
        value: "0"
    },
    {
        name: "1",
        value: "1"
    },
    {
        name: "2",
        value: "2"
    },
    {
        name: "3",
        value: "3"
    },
    {
        name: "4",
        value: "4"
    },
    {
        name: "5",
        value: "5"
    },
    {
        name: "6",
        value: "6"
    },
    {
        name: "7",
        value: "7"
    },
    {
        name: "8",
        value: "8"
    },
    {
        name: "9",
        value: "9"
    },
    {
        name: "10",
        value: "10"
    },
    {
        name: "11",
        value: "11"
    },
    {
        name: "12",
        value: "12"
    },
    {
        name: "13",
        value: "13"
    },
    {
        name: "14",
        value: "14"
    },
    {
        name: "15",
        value: "15"
    },
    {
        name: "16",
        value: "16"
    },
    {
        name: "17",
        value: "17"
    },
    {
        name: "18",
        value: "18"
    },
    {
        name: "19",
        value: "19"
    },
    {
        name: "20",
        value: "20"
    },
    {
        name: "21",
        value: "21"
    },
    {
        name: "22",
        value: "22"
    },
    {
        name: "23",
        value: "23"
    }
];

const monthsList = [
    {
        name: "Every Month",
        value: "*"
    },
    {
        name: "January",
        value: "1"
    },
    {
        name: "February",
        value: "2"
    },
    {
        name: "March",
        value: "3"
    },
    {
        name: "April",
        value: "4"
    },
    {
        name: "May",
        value: "5"
    },
    {
        name: "June",
        value: "6"
    },
    {
        name: "July",
        value: "7"
    },
    {
        name: "August",
        value: "8"
    },
    {
        name: "September",
        value: "9"
    },
    {
        name: "October",
        value: "10"
    },
    {
        name: "November",
        value: "11"
    },
    {
        name: "December",
        value: "12"
    }
];

const daysList = [
    {
        name: "Every Day",
        value: "*"
    },
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    { name: "6", value: "6" },
    { name: "7", value: "7" },
    { name: "8", value: "8" },
    { name: "9", value: "9" },
    { name: "10", value: "10" },
    { name: "11", value: "11" },
    { name: "12", value: "12" },
    { name: "13", value: "13" },
    { name: "14", value: "14" },
    { name: "15", value: "15" },
    { name: "16", value: "16" },
    { name: "17", value: "17" },
    { name: "18", value: "18" },
    { name: "19", value: "19" },
    { name: "20", value: "20" },
    { name: "21", value: "21" },
    { name: "22", value: "22" },
    { name: "23", value: "23" },
    { name: "24", value: "24" },
    { name: "25", value: "25" },
    { name: "26", value: "26" },
    { name: "27", value: "27" },
    { name: "28", value: "28" },
    { name: "29", value: "29" },
    { name: "30", value: "30" },
    { name: "31", value: "31" }
];

const weekdaysList = [
    {
        name: "Every Weekday",
        value: "*"
    },
    {
        name: "Sunday",
        value: "SUN"
    },
    {
        name: "Monday",
        value: "MON"
    },
    {
        name: "Tuesday",
        value: "TUE"
    },
    {
        name: "Wednesday",
        value: "WED"
    },
    {
        name: "Thursday",
        value: "THU"
    },
    {
        name: "Friday",
        value: "FRI"
    },
    {
        name: "Saturday",
        value: "SAT"
    }
];

const insertAttributesList = [{
    name: 'Proposal ID',
    value: '{~Proposal ID~}'
}, {
    name: 'Activity ID',
    value: '{~Activity ID~}'
}, {
    name: 'First Name',
    value: '{~First Name~}'
}, {
    name: 'Last Name',
    value: '{~Last Name~}'
}, {
    name: 'Address',
    value: '{~Shipping Address Line 1~}'
}, {
    name: 'Zipcode',
    value: '{~Shipping Address Postal Code~}'
}, {
    name: 'City',
    value: '{~Shipping Address City~}'
}, {
    name: 'State',
    value: '{~Shipping Address State~}'
}, {
    name: 'Phone Number',
    value: '{~Phone~}'
}, {
    name: 'Email',
    value: '{~Email~}'
}, {
    name: 'Shipping Address Line 2',
    value: '{~Shipping Address Line 2~}'
}, {
    name: 'Billing Address Line 1',
    value: '{~Billing Address Line 1~}'
}, {
    name: 'Billing Address Line 2',
    value: '{~Billing Address Line 2~}'
}, {
    name: 'Billing Address City',
    value: '{~Billing Address City~}'
}, {
    name: 'Billing Address Postal Code',
    value: '{~Billing Address Postal Code~}'
}, {
    name: 'Proposal Name',
    value: '{~Proposal Name~}'
}, {
    name: 'Status',
    value: '{~Proposal Status~}'
}, {
    name: 'Type',
    value: '{~Proposal Type~}'
}, {
    name: 'Proposal Source',
    value: '{~Proposal Source~}'
}, {
    name: 'Proposal Number',
    value: '{~Proposal Number~}'
}, {
    name: 'Date',
    value: '{~Date~}'
}, {
    name: 'Time',
    value: '{~Time~}'
}, {
    name: 'Subtotal',
    value: '{~Subtotal~}'
}, {
    name: 'Tax Total',
    value: '{~Tax Total~}'
}, {
    name: 'Total',
    value: '{~Total~}'
}, {
    name: 'Tax Name',
    value: '{~Tax Name~}'
}, {
    name: 'Tax Rate',
    value: '{~Tax Rate~}'
}, {
    name: 'Number Of Items',
    value: '{~Number Of Items~}'
}, {
    name: 'Job Details',
    value: '{~Job Details~}'
}
];

const states = [
    {
        value: "AL",
        name: "Alabama"
    },
    {
        value: "AK",
        name: "Alaska"
    },
    {
        value: "AS",
        name: "American Samoa"
    },
    {
        value: "AZ",
        name: "Arizona"
    },
    {
        value: "AR",
        name: "Arkansas"
    },
    {
        value: "CA",
        name: "California"
    },
    {
        value: "CO",
        name: "Colorado"
    },
    {
        value: "CT",
        name: "Connecticut"
    },
    {
        value: "DE",
        name: "Delaware"
    },
    {
        value: "DC",
        name: "District Of Columbia"
    },
    {
        value: "FM",
        name: "Federated States Of Micronesia"
    },
    {
        value: "FL",
        name: "Florida"
    },
    {
        value: "GA",
        name: "Georgia"
    },
    {
        value: "GU",
        name: "Guam"
    },
    {
        value: "HI",
        name: "Hawaii"
    },
    {
        value: "ID",
        name: "Idaho"
    },
    {
        value: "IL",
        name: "Illinois"
    },
    {
        value: "IN",
        name: "Indiana"
    },
    {
        value: "IA",
        name: "Iowa"
    },
    {
        value: "KS",
        name: "Kansas"
    },
    {
        value: "KY",
        name: "Kentucky"
    },
    {
        value: "LA",
        name: "Louisiana"
    },
    {
        value: "ME",
        name: "Maine"
    },
    {
        value: "MH",
        name: "Marshall Islands"
    },
    {
        value: "MD",
        name: "Maryland"
    },
    {
        value: "MA",
        name: "Massachusetts"
    },
    {
        value: "MI",
        name: "Michigan"
    },
    {
        value: "MN",
        name: "Minnesota"
    },
    {
        value: "MS",
        name: "Mississippi"
    },
    {
        value: "MO",
        name: "Missouri"
    },
    {
        value: "MT",
        name: "Montana"
    },
    {
        value: "NE",
        name: "Nebraska"
    },
    {
        value: "NV",
        name: "Nevada"
    },
    {
        value: "NH",
        name: "New Hampshire"
    },
    {
        value: "NJ",
        name: "New Jersey"
    },
    {
        value: "NM",
        name: "New Mexico"
    },
    {
        value: "NY",
        name: "New York"
    },
    {
        value: "NC",
        name: "North Carolina"
    },
    {
        value: "ND",
        name: "North Dakota"
    },
    {
        value: "MP",
        name: "Northern Mariana Islands"
    },
    {
        value: "OH",
        name: "Ohio"
    },
    {
        value: "OK",
        name: "Oklahoma"
    },
    {
        value: "OR",
        name: "Oregon"
    },
    {
        value: "PW",
        name: "Palau"
    },
    {
        value: "PA",
        name: "Pennsylvania"
    },
    {
        value: "PR",
        name: "Puerto Rico"
    },
    {
        value: "RI",
        name: "Rhode Island"
    },
    {
        value: "SC",
        name: "South Carolina"
    },
    {
        value: "SD",
        name: "South Dakota"
    },
    {
        value: "TN",
        name: "Tennessee"
    },
    {
        value: "TX",
        name: "Texas"
    },
    {
        value: "UT",
        name: "Utah"
    },
    {
        value: "VT",
        name: "Vermont"
    },
    {
        value: "VI",
        name: "Virgin Islands"
    },
    {
        value: "VA",
        name: "Virginia"
    },
    {
        value: "WA",
        name: "Washington"
    },
    {
        value: "WV",
        name: "West Virginia"
    },
    {
        value: "WI",
        name: "Wisconsin"
    },
    {
        value: "WY",
        name: "Wyoming"
    }
];


const source = [
    {
        name: "Google",
        value: "Google"
    }, {
        name: "Yahoo",
        value: "Yahoo"
    }, {
        name: "Amazon",
        value: "Amazon"
    }, {
        name: "Friends (Please tell us below)",
        value: "friends"
    }, {
        name: "Other (Please tell us below)",
        value: "Other"
    }, {
        name: "Radio",
        value: "radio"
    }, {
        name: "Newspaper",
        value: "newspaper"
    }
];


const proposal_status = [
    {
        name: 'Quoted',
        value: 'Quoted'
    }, {
        name: 'Won',
        value: 'Won'
    }, {
        name: 'Lost',
        value: 'Lost'
    }, {
        name: 'Completed',
        value: 'Completed'
    }, {
        name: 'In Progress',
        value: 'In Progress'
    }
]


const dayOrMonthList = [
    {
        name: 'Days',
        value: 'DAY'
    }, {
        name: 'Months',
        value: 'MONTH'
    }
]

const operationList = [
    {
        name: 'equal to',
        value: '='
    },
    {
        name: 'greater than',
        value: '>'
    }, {
        name: 'less than',
        value: '<'
    }
]


const jobTypeList = [
    {
        name: 'Tree Trimming Class I Fine Pruning',
        value: 'Tree Trimming Class I Fine Pruning'
    }, 
    {
        name: 'Tree Trimming Class II Standard Pruning ',
        value: 'Tree Trimming Class II Standard Pruning '
    }, 
    {
        name: 'Tree Trimming Class III Hazard Pruning',
        value: 'Tree Trimming Class III Hazard Pruning'
    },
    {
        name: 'Cabling',
        value: 'Cabling'
    }, {
        name: 'Cc Fee',
        value: 'Cc Fee'
    }, {
        name: 'Consultation',
        value: 'Consultation'
    }, {
        name: 'Consulting',
        value: 'Consulting'
    }, {
        name: 'Cut & Leave Debris',
        value: 'Cut & Leave Debris'
    }, {
        name: 'Daily 8 Hour Rate',
        value: 'Daily 8 Hour Rate'
    }, {
        name: 'Daily Rate',
        value: 'Daily Rate'
    }, {
        name: 'Debris Removal',
        value: 'Debris Removal'
    }, {
        name: 'Debris Removal (Hauling debris)',
        value: 'Debris Removal (Hauling debris)'
    }, {
        name: 'Discount',
        value: 'Discount'
    }, {
        name: 'Landscaping',
        value: 'Landscaping'
    }, {
        name: 'Other',
        value: 'Other'
    }, {
        name: 'Payment',
        value: 'Payment'
    }, {
        name: 'Stump Grinding',
        value: 'Stump Grinding'
    }, {
        name: 'Tree Removal',
        value: 'Tree Removal'
    }, {
        name: 'Tree Treatment',
        value: 'Tree Treatment'
    }, 
    {
        name: 'Tree Trimming',
        value: 'Tree Trimming'
    }, 
    {
        name: 'Tree Trimming Class II',
        value: 'Tree Trimming Class II'
    },  
    {
        name: 'Tree Trimming Class III',
        value: 'Tree Trimming Class III'
    }, 
    {
        name: 'Tree Trimming Class IV',
        value: 'Tree Trimming Class IV'
    }, 
    {
        name: 'Tree Trimming Class IV Crown Reduction.',
        value: 'Tree Trimming Class IV Crown Reduction.'
    }, 
    {
        name: 'Tree Trimming I',
        value: 'Tree Trimming I'
    }
]
export { minutesList, hoursList, monthsList, daysList, weekdaysList, insertAttributesList, states, source, proposal_status, dayOrMonthList,operationList, jobTypeList };