import React from 'react';
import { TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import 'bootstrap/dist/css/bootstrap.min.css';

export const FormInput = (
    { placeholder,
        name,
        error,
        label,
        id,
        value,
        onChange,
        helperText,
        multiline,
        rows,
        ...inputProps }) => {


    const onHandleChange = (e) => {
        onChange(e);
    }
    return (
        <FormControl sx={{ mt: 3, ml: 1, width: '100%' }}>
            <TextField
                label={label}
                id={id}
                name={name}
                value={value}
                multiline={multiline}
                placeholder={placeholder}
                variant="standard"
                onChange={onHandleChange}
                helpertext={helperText}
                error={error}
                rows={rows}
            />
        </FormControl>
    )
}