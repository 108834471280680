import Modal from '@mui/material/Modal';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import Box from '@mui/material/Box';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Typography from '@mui/material/Typography';
import { Divider } from "@mui/material";
import { Row, Col } from "reactstrap";

export const SentEmailsModal = ({ token, setValue, value }) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
        bgcolor: 'background.paper',
        borderRadius: '15px',
        boxShadow: "0 5px 5px 0 rgba(0,0,0,.25)",
        p: 4,
        overflowY: "auto",
        color: "#333"
    };
    const handleClose = () => {
        setValue('');
    };



    return (<Modal open={value ? true : false} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CloseRoundedIcon
                    onClick={handleClose}
                    sx={{ cursor: 'pointer' }}
                />
            </Box>
            <Typography id="modal-modal-title" variant="h4">Sent Emails</Typography>
            <Divider />
            <Row style={{paddingTop:"1%"}}>
                <Col sm="1">Subject:</Col>
                <Col sm="6">{value.subject}</Col>
            </Row>
            <Row>
                <Col sm="1">To:</Col>
                <Col sm="6">{value.email_id}</Col>
            </Row>
           
            <Box dangerouslySetInnerHTML={{ __html: value.email_body }} />

        </Box>
    </Modal>);
};