import { Auth0Provider } from "@auth0/auth0-react";
import App from './App';
import './App.css';
import { MiniDrawer } from './components/Drawer';
import { InvoicePage } from './pages/InvoicePage/InvoicePage';
import { QuickbooksPage } from './pages/QuickbooksPage/QuickbooksPage';
import { JobsPage } from './pages/JobsPage/JobsPage';

import React from 'react';
import { LoginButton } from './pages/login';
import { useAuth0 } from '@auth0/auth0-react';
import { ContactForms } from './pages/ContactForm/ContactForm';
import { Route, Routes, BrowserRouter, } from 'react-router-dom';
import Error from './components/Error';
import { Map } from './pages/Map';
import { useEffect } from 'react'

export const LoginApp = () => {
    const [componentIndex, useComponentIndex] = React.useState(0);
    const components = [InvoicePage, QuickbooksPage, ContactForms, JobsPage, Map];
    const { isAuthenticated } = useAuth0();
    useEffect(() => {
        console.log(isAuthenticated);
    }, [isAuthenticated]);

    return (
        <Auth0Provider
            domain="dev-s001fnzy.us.auth0.com"
            clientId="wDn3z2bE7MI3z0mgPwdnSduXIz8wVV6C"
            redirectUri={process.env.REACT_APP_BASE_URL}
        >
            <App/>
        </Auth0Provider>)
}