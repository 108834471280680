import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



export const DialogBox = ({open, setOpen, description, handleEnqueueConfirmation}) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color='warning' onClick={handleClose}>Revert</Button>
          <Button 
            variant="contained"
            onClick={handleEnqueueConfirmation} 
            sx={{
              backgroundColor: '#1b867b', 
              color: '#ffffff', 
              '&:hover': {
                  backgroundColor: '#136a61', 
                  color: '#fff',
                  borderColor:'#1b867b'
              }
            }}
            autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>);
}