import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Form } from "reactstrap";


export const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <Box>
            <MuiAppBar style={{background:"#1C2B36"}}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                    <span className="appbar-title-text">Life</span>
                    <span className="appbar-title-text-hover">TreeWNY</span>
                    </Typography>

                </Toolbar>
            </MuiAppBar>
            <Box component="main" sx={{ mt: 10 }}>

                <Form onSubmit={loginWithRedirect}>

                        <Box textAlign='center' >
                            <Button variant="contained" type="submit" style={{background: "#1b867b"}}>
                                Login
                            </Button>
                        </Box>
                </Form>
            </Box>
        </Box>
    )
}