import React from 'react';

import { LoginButton } from '../pages/login';

import { useAuth0 } from "@auth0/auth0-react";

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
        height: 200,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
}));

const Error = () => {
    const classes = useStyles();
    const { logout } = useAuth0();
    return (
        <div>
            <Box>
                <MuiAppBar style={{ background: "#1C2B36" }}>
                    <Toolbar>
                        <Typography variant="h6" noWrap component="div">
                            <span className="appbar-title-text">Life</span>
                            <span className="appbar-title-text-hover">TreeWNY</span>
                        </Typography>

                    </Toolbar>

                </MuiAppBar>

                <Typography
                    className={classes.root}
                    variant="h3"
                    align="left">
                    404 Page Not Found

                </Typography>
                <Button variant="contained" onClick={() => logout({ returnTo: process.env.REACT_APP_BASE_URL })}>
                    Go Back
                </Button>
            </Box>
        </div>

    )
}

export default Error;